<script lang="ts">
    import type { Proposal } from "../services/detail.service";
    import Config from "../../../core/config/index";

    export let proposal: Proposal;

    function getSizeOfFile(file: any) {
        var result: string;
        var size = file ? file.velikost : 0;

        if (size || size == 0) {
            if (size > 1024) {
                size = size / 1024;

                if (size > 1024) {
                    size = size / 1024;
                    size = Math.round(<any>(size + "e+2")) + "e-2";
                    result = Math.round(size) + " MB";
                } else {
                    size = Math.round(<any>(size + "e+2")) + "e-2";
                    result = Math.round(size) + " KB";
                }
            } else {
                result = Math.round(size) + " B";
            }
        }

        return result;
    }

    function getIcon(nazev: string) {
        let result = "doc";

        if (nazev.toLowerCase().endsWith("pdf")) {
            result = "pdf";
        }

        return result;
    }

    function getSizeAndTypeOfFile(priloha: any) {
        let result = "";

        if (priloha && (priloha.velikost || priloha.velikost == 0)) {
            result = getSizeOfFile(priloha);
        }

        if (priloha.nazev) {
            let idx = priloha.nazev.lastIndexOf(".");
            if (idx != -1) {
                result += result
                    ? ", " + priloha.nazev.substr(idx + 1).toUpperCase()
                    : priloha.nazev.substr(idx + 1).toUpperCase();
            }
        }

        return "(" + result + ")";
    }
</script>

{#if proposal}
    <h2>Přílohy návrhu</h2>

    <ul class="bno-list-plain mt-3">
        {#each proposal.prilohy as priloha}
            <li>
                <a
                    class="bno-block-link bno-link--without-external-icon"
                    href="{Config.imageBaseUrl}/{priloha.obsahSouboru}/content"
                    target="_blank"
                >
                    <span class="bno-icon bno-icon--{getIcon(priloha.nazev)}" />

                    <div class="bno-block-link__content">
                        <span class="bno-link bno-link--standalone">
                            {priloha.nazev}

                            <!-- {#if priloha.velikost || priloha.velikost == 0}
                                <span class="bno-link__suffix"
                                    >{getSizeAndTypeOfFile(priloha)}</span
                                >
                            {/if} -->
                        </span>
                    </div>
                </a>
            </li>
        {/each}
    </ul>
{/if}

<style>
    .mt-3 {
        list-style: none;
        margin: 0;
        padding: 0;
        margin-top: 1.875rem;
        box-sizing: border-box;
    }

    ul > li:before {
        content: none;
    }

    li {
        margin-top: 0;
    }
</style>
