<script lang="ts">
    import { createEventDispatcher } from "svelte";
    import { onMount, onDestroy } from "svelte";
    import Config from "../../core/config/index";

    export let coords: { x: number; y: number } | null = null;

    const dispatch = createEventDispatcher();
    let spinBox: HTMLDivElement = null;

    // funkce pro inicializaci mapy
    function spinBoxInit(win, command) {
        var commandBase64 = encodeURIComponent(btoa(JSON.stringify(command)));
        win.src =
            Config.mapBaseUrl + "mapa/paro/?lo=mini&init=" + commandBase64;
    }

    onMount(() => {
        if (!coords) {
            spinBoxInit(spinBox, [
                {
                    cmd: "editGeometry",
                    insertType: "Point",
                    format: "GeoJSON",
                    crs: "EPSG:5514",
                    target: "top", //in spinbox used for send postMessage on window[target]
                },
            ]);
        } else {
            if (coords.x && coords.y) {
                spinBoxInit(spinBox, [
                    {
                        cmd: "showGeometry", //showGeometry || editGeometry
                        insertType: null,
                        geometry:
                            '{"type":"Point","coordinates":[' +
                            coords.x +
                            "," +
                            coords.y +
                            "]}",
                        format: "GeoJSON",
                        crs: "EPSG:5514",
                        target: "top", //in spinbox used for send postMessage on window[target]
                    },
                ]);
            } else {
                spinBoxInit(spinBox, [
                    {
                        cmd: "interfaceReady",
                        format: "GeoJSON",
                        crs: "EPSG:5514",
                        target: "top",
                    },
                ]);
            }
        }
    });

    onDestroy(() => {
        spinBoxInit(spinBox, [
            {
                cmd: "editGeometryAdvanced",
                deactivate: true,
                target: "top",
            },
        ]);
    });

    window.onmessage = function (e: any) {
        switch (e.data.sbx.req.cmd) {
            case "interfaceReady":
                // console.log("Spinbox editGeometryAdvanced READY!");
                break;
            case "editGeometryAdvanced":
                // console.log("edit advanced");
                dispatch("placeSelect", e.data.sbx.res.geometry);
                break;
            case "editGeometry":
                // console.log(e.data);
                dispatch("placeSelect", e.data.sbx.res.geometry);
                break;
            default:
                // console.log(e.data.sbx);
                break;
        }
    };
</script>

<div style="height: 400px; margin-top:3.125rem;">
    <iframe
        title="Mapa"
        id="spinBox"
        name="spinBox"
        src=""
        bind:this={spinBox}
        style="overflow: hidden; height:100%; width:100%;"
    />
</div>
