import CharUtils from './char-utils';
import DateUtils from './date-utils';
export default new class {
    sortByValue(aItem, bItem) {
        if (aItem && bItem && aItem.value && bItem.value) {
            var aNazev = CharUtils.useDiacritics(aItem.value);
            var bNazev = CharUtils.useDiacritics(bItem.value);
            if (aNazev < bNazev) {
                return -1;
            }
            else if (aNazev > bNazev) {
                return 1;
            }
        }
        return 0;
    }
    sortByLabel(aItem, bItem) {
        if (aItem && bItem && aItem.label && bItem.label) {
            var aNazev = CharUtils.useDiacritics(aItem.label);
            var bNazev = CharUtils.useDiacritics(bItem.label);
            if (aNazev < bNazev) {
                return -1;
            }
            else if (aNazev > bNazev) {
                return 1;
            }
        }
        return 0;
    }
    sortByNazev(aItem, bItem) {
        if (aItem && bItem && aItem.nazev && bItem.nazev && aItem.nazev.trim() && bItem.nazev.trim()) {
            var aNazev = CharUtils.useDiacritics(aItem.nazev.trim());
            var bNazev = CharUtils.useDiacritics(bItem.nazev.trim());
            if (aNazev < bNazev) {
                return -1;
            }
            else if (aNazev > bNazev) {
                return 1;
            }
        }
        return 0;
    }
    sortById(aItem, bItem) {
        if (aItem && bItem && aItem.id && bItem.id) {
            var aId = aItem.id;
            var bId = bItem.id;
            if (aId > bId) {
                return -1;
            }
            else if (aId < bId) {
                return 1;
            }
        }
        return 0;
    }
    sortByPoradi(aItem, bItem) {
        if (aItem && bItem && aItem.poradi && bItem.poradi) {
            var aPoradi = aItem.poradi;
            var bPoradi = bItem.poradi;
            if (aPoradi < bPoradi) {
                return -1;
            }
            else if (aPoradi > bPoradi) {
                return 1;
            }
        }
        return 0;
    }
    sortByIdAsc(aItem, bItem) {
        if (aItem && bItem && aItem.id && bItem.id) {
            var aId = aItem.id;
            var bId = bItem.id;
            if (aId < bId) {
                return -1;
            }
            else if (aId > bId) {
                return 1;
            }
        }
        return 0;
    }
    sortByDate(aItem, bItem) {
        if (aItem && bItem && aItem.datumVlozeni && bItem.datumVlozeni) {
            var aDate = aItem.datumVlozeni;
            var bDate = bItem.datumVlozeni;
            if (DateUtils.compareDateLater(aDate, bDate)) {
                return -1;
            }
            else if (DateUtils.compareDateLater(bDate, aDate)) {
                return 1;
            }
        }
        return 0;
    }
    sortByDatumPrirazeni(aItem, bItem) {
        if (aItem && bItem && aItem.datumPrirazeni && bItem.datumPrirazeni) {
            var aDate = aItem.datumPrirazeni;
            var bDate = bItem.datumPrirazeni;
            if (DateUtils.compareDateLater(aDate, bDate)) {
                return -1;
            }
            else if (DateUtils.compareDateLater(bDate, aDate)) {
                return 1;
            }
        }
        return 0;
    }
};
