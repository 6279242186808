import { writable } from "svelte/store";
import $axios from "../core/rest/axios";
import CodeListUtils from "../utils/code-list-utils";
import SortUtils from "../utils/sort-utils";
export default class DataService {
    constructor() {
        this._statuses = writable([]);
        this._areas = writable([]);
        this._areasValid = writable([]);
        this._errorStatus = writable(false);
        this._processingStatus = writable(false);
        this._errorArea = writable(false);
        this._processingArea = writable(false);
    }
    loadAreas() {
        this._processingArea.set(true);
        $axios.get("/servlet/proxy/enavrhy/kategorie")
            .then((response) => {
            this._areas.set(this.sortCategory(response.data));
        })
            .catch(() => this._errorArea.set(true))
            .finally(() => this._processingArea.set(false));
        return;
    }
    loadValidAreas() {
        this._processingArea.set(true);
        $axios.get("/servlet/proxy/enavrhy/kategorie/platne")
            .then((response) => {
            this._areasValid.set(this.sortCategory(response.data));
        })
            .catch(() => this._errorArea.set(true))
            .finally(() => this._processingArea.set(false));
        return;
    }
    loadStatuses() {
        this._processingStatus.set(true);
        $axios.get("/servlet/proxy/enavrhy/stavy")
            .then((response) => {
            this._statuses.set(this.filterStavy(response.data));
        })
            .catch(() => this._errorStatus.set(true))
            .finally(() => this._processingStatus.set(false));
        return;
    }
    filterStavy(data) {
        let result = [];
        if (data && data.length > 0) {
            data.forEach((item) => {
                if (item && item.nazevVerejny) {
                    let idx = result.findIndex(x => x.nazevVerejny == item.nazevVerejny);
                    if (idx != -1) {
                        result[idx].elements.push(item.id);
                    }
                    else {
                        item.elements = [item.id];
                        result.push(item);
                    }
                }
            });
        }
        return result;
    }
    sortCategory(kategorie) {
        let result = [];
        // console.log(kategorie);
        if (kategorie && kategorie.length > 0) {
            let jiny = CodeListUtils.vypisCiselnikovuPolozkuByNazev(kategorie, "Jiné");
            // console.log(jiny);
            if (jiny && jiny.id) {
                kategorie.forEach((item) => {
                    if (item && item.id && item.id != jiny.id) {
                        result.push(item);
                    }
                });
            }
            result.sort(SortUtils.sortByNazev);
            result.push(jiny);
        }
        // console.log(result);
        return result;
    }
    get areasSubs() {
        return this._areas;
    }
    get areasValidSubs() {
        return this._areasValid;
    }
    get statusesSubs() {
        return this._statuses;
    }
}
