<script lang="ts">
    import { getContext } from "svelte";

    export let required: boolean = false;
    export let disabled: boolean = false;
    export let label: string = undefined;
    export let name: string;
    export let style: string = undefined;
    export let value: boolean = false;
    export let title: string = "";
    export let hint: string = "";

    let hintOpened: boolean = false;

    const id = Math.random() + "";

    const { onBlur } = getContext("form");

    const onChange = (e: any) => (value = e.target.checked);

    const onCheckboxChange = (e: any) => {
        value = !value;
    };
</script>

<div class="bno-form-control bno-form-control--custom" {style}>
    <input
        class="bno-form-control__checkbox"
        {id}
        type="checkbox"
        {disabled}
        {name}
        bind:checked={value}
        on:blur={onBlur}
        on:change={onChange}
        aria-required={required}
        aria-disabled={disabled}
    />
    {#if label}
        <label {title} class="bno-form-control__label" for={id}>
            {label}
            {#if hint}
                <span
                    class="bno-icon bno-icon--16x16 bno-icon--info"
                    on:click|preventDefault={() => (hintOpened = !hintOpened)}
                />
            {/if}
        </label>
    {:else}
        <label {title} class="bno-form-control__label" for={id}>
            <slot name="label" />
        </label>
    {/if}
    <span class="bno-form-control__indicator" on:click={onCheckboxChange} />
    <slot name="errors" />
    {#if hintOpened}
        <mark class="bno-form-control__message">{hint}</mark>
    {/if}
</div>
