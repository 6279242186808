<script lang="ts">
    import type { Service } from "./providers/service.provider";
    import type { Proposal } from "./services/detail.service";
    import type { Data } from "./providers/data.provider";
    import ProjectDiary from "./components/project-diary.svelte";
    import ProposalAttachments from "./components/proposal-attachments.svelte";
    import HeaderBar from "./components/header-bar.svelte";
    import SideBar from "./components/side-bar.svelte";
    import SMap from "../../components/Map/SMap.svelte";
    import Page404 from "../../components/page-404.svelte";

    export let serviceProvider: Service;
    export let dataProvider: Data;

    let proposal: Proposal;
    let processing: boolean = false;
    let error: boolean = false;

    serviceProvider.detailService.proposalSubs.subscribe(
        (data) => (proposal = data)
    );
    serviceProvider.detailService.processingSubs.subscribe(
        (status) => (processing = status)
    );
    serviceProvider.detailService.errorSubs.subscribe(
        (status) => (error = status)
    );

    serviceProvider.detailService.load(dataProvider.options.proposalId);

    const onVoteSuccess = (e: any): void => {
        if (e) {
            if (e.detail === 1) {
                proposal.pocetHlasuPozitivnich++;
            } else if (e.detail == 0) {
                proposal.pocetHlasuNegativnich++;
            }
        }
    };
</script>

{#if processing}
    <main tabindex="-1" id="bno-main">
        <div class="bno-main">
            <div class="bno-container">
                <div class="bno-container__content">
                    <p>Načítám...</p>
                </div>
            </div>
        </div>
    </main>
{:else if proposal}
    <main tabindex="-1" id="bno-main">
        <header class="bno-hero-banner bno-hero-banner--height-auto">
            <div class="bno-container">
                <div class="bno-container__content">
                    <h1 class="is-plain">{proposal.nazev}</h1>
                </div>
            </div>
        </header>

        <div class="bno-main">
            <HeaderBar
                {proposal}
                {serviceProvider}
                {dataProvider}
                on:voteSuccess={onVoteSuccess}
            />

            <div class="bno-container">
                <div class="bno-container__content">
                    <div class="bno-layout bno-layout--ratio-3-1">
                        <div class="bno-layout__col bno-layout__col--spread-3">
                            <h2>Popis návrhu</h2>

                            <p class="bno-perex">
                                {proposal.popis}
                            </p>

                            <h2>Veřejný přínos</h2>

                            <p class="like-pre">{proposal.prospech}</p>

                            {#if proposal.gpsSouradnice && proposal.gpsSouradnice.souradniceX && proposal.gpsSouradnice.souradniceY}
                                <SMap
                                    coords={{
                                        x: proposal.gpsSouradnice.souradniceX,
                                        y: proposal.gpsSouradnice.souradniceY,
                                    }}
                                />
                            {/if}

                            {#if proposal.denikRealizace && proposal.denikRealizace.length > 0}
                                <ProjectDiary {proposal} />
                            {/if}

                            {#if proposal.prilohy && proposal.prilohy.length > 0}
                                <ProposalAttachments {proposal} />
                            {/if}
                        </div>

                        <div class="bno-layout__col">
                            <SideBar {proposal} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
{:else if error}
    <Page404 />
{/if}

<style>
    .bno-perex,
    .like-pre {
        white-space: -moz-pre-wrap;

        /* Opera 4-6 */
        white-space: -pre-wrap;

        /* Opera 7 */
        white-space: -o-pre-wrap;

        /* css-3 */
        white-space: pre-wrap;

        /* Internet Explorer 5.5+ */
        word-wrap: break-word;
    }
</style>
