<script lang="ts">
    import type { Service } from "./providers/service.provider";
    import type { ProposalFilter } from "./services/list.service";

    export let serviceProvider: Service;

    let data: ProposalFilter = serviceProvider.listService.filter;
    serviceProvider.listService.filterSubs.subscribe(
        (filter) => (data = filter)
    );

    function toggleSort(): void {
        data.pagingAndSorting.asc = !data.pagingAndSorting.asc;

        serviceProvider.listService.sort();

        data = data;
    }

    function changeSort(): void {
        data.pagingAndSorting.asc = false;

        serviceProvider.listService.sort();

        data = data;
    }
</script>

<div class="bno-list-sort__container">
    <span>Seřadit</span>

    <div class="bno-form-control">
        <div class="bno-select">
            <select
                id="selectRazeni"
                name="selectRazeni"
                aria-required="false"
                aria-disabled="false"
                bind:value={data.pagingAndSorting.order}
                on:change={changeSort}
            >
                <option value="">Nevybráno</option>
                <option value="datumZverejneni">Datum vložení</option>
                <option value="pocetHlasuPozitivnich"
                    >Počet podporujících</option
                >
            </select>

            <label class="bno-form-control__label u-sr-only" for="selectRazeni">
                Řazení záznamů
            </label>
        </div>
    </div>

    <button
        type="button"
        aria-label="Seřadit vzestupně"
        class="bno-list-sort__button"
        class:bno-list-sort__button--active={data.pagingAndSorting.asc}
        on:click={toggleSort}
    >
        <span
            class="bno-icon bno-icon--arrow bno-icon--16x16 bno-icon--direction-up"
        />
    </button>
    <button
        type="button"
        aria-label="Seřadit sestupně"
        class="bno-list-sort__button"
        class:bno-list-sort__button--active={!data.pagingAndSorting.asc}
        on:click={toggleSort}
    >
        <span
            class="bno-icon bno-icon--arrow bno-icon--16x16 bno-icon--direction-down"
        />
    </button>
</div>
