<script lang="ts">
    import { getContext } from "svelte";
    import jwPaginate from "jw-paginate";
    import type { Pager } from "./pager.service";

    const { goToPage, pager }: { goToPage: Function; pager: Pager } =
        getContext("pager");

    let paginate: any;
    pager.pagerSubs.subscribe(
        (pager) =>
            (paginate = jwPaginate(
                pager.total,
                pager.page,
                pager.perPage,
                pager.maxPages
            ))
    );
    const setPage = (page: number): void => goToPage(page);
</script>

{#if paginate.pages && paginate.pages.length > 1}
    <div class="bno-pagination__wrap">
        <nav class="bno-pagination" aria-label="Stránkování návrhů">
            {#if paginate.pages.length > 1}
                <ol class="bno-pagination__items">
                    {#if paginate.currentPage !== 1}
                        <li
                            class="bno-pagination__item"
                            class:disabled={paginate.currentPage === 1}
                        >
                            <a
                                class="bno-pagination__link"
                                aria-label="První strana"
                                href={"#"}
                                on:click|preventDefault={() => setPage(1)}
                            >
                                <span aria-hidden="true">«</span>
                                <span class="u-sr-only">První strana</span>
                            </a>
                        </li>
                        <li
                            class="bno-pagination__item"
                            class:disabled={paginate.currentPage === 1}
                        >
                            <a
                                class="bno-pagination__link"
                                aria-label="Předchozí strana"
                                href={"#"}
                                on:click|preventDefault={() =>
                                    setPage(paginate.currentPage - 1)}
                            >
                                <span aria-hidden="true">‹</span>
                                <span class="u-sr-only">Předchozí strana</span>
                            </a>
                        </li>
                    {/if}
                    {#if paginate.startPage > 1}
                        <li class="bno-pagination__item" role="presentation">
                            <!-- svelte-ignore a11y-missing-attribute -->
                            <a
                                class="bno-pagination__link is-ellipsis"
                                aria-label="…">…</a
                            >
                        </li>
                    {/if}
                    {#each paginate.pages as page}
                        <li class="bno-pagination__item">
                            <a
                                class="bno-pagination__link"
                                class:is-active={paginate.currentPage === page}
                                aria-label="Strana {page}"
                                aria-current={paginate.currentPage === page
                                    ? "page"
                                    : undefined}
                                href={"#"}
                                on:click|preventDefault={() => setPage(page)}
                            >
                                <span class="u-sr-only">Strana</span>
                                {page}
                            </a>
                        </li>
                    {/each}
                    {#if paginate.endPage < paginate.totalPages}
                        <li class="bno-pagination__item" role="presentation">
                            <!-- svelte-ignore a11y-missing-attribute -->
                            <a
                                class="bno-pagination__link is-ellipsis"
                                aria-label="…">…</a
                            >
                        </li>
                    {/if}
                    {#if paginate.currentPage !== paginate.totalPages}
                        <li class="bno-pagination__item">
                            <a
                                class="bno-pagination__link"
                                aria-label="Následující strana"
                                href={"#"}
                                on:click|preventDefault={() =>
                                    setPage(paginate.currentPage + 1)}
                            >
                                <span aria-hidden="true">›</span>
                                <span class="u-sr-only">Následující strana</span
                                >
                            </a>
                        </li>
                        <li class="bno-pagination__item">
                            <a
                                class="bno-pagination__link"
                                aria-label="Poslední strana"
                                href={"#"}
                                on:click|preventDefault={() =>
                                    setPage(paginate.totalPages)}
                            >
                                <span aria-hidden="true">»</span>
                                <span class="u-sr-only">Poslední strana</span>
                            </a>
                        </li>
                    {/if}
                </ol>
            {/if}
        </nav>
    </div>
{/if}
