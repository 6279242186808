<script lang="ts">
    import { getContext } from "svelte";

    export let required: boolean = false;
    export let disabled: boolean = false;
    export let placeholder: string = undefined;
    export let type: string = "text";
    export let title: string = "";
    export let label: string = undefined;
    export let name: string;
    export let maxlength: number = 100;
    export let style: string = undefined;
    export let value: string | null | null = undefined;
    export let hint: string = "";
    export let classes: string = "";
    export let labelClass: string = "";

    let hintOpened: boolean = false;

    const id = Math.random() + "";

    const { onBlur } = getContext("form");
    const onKeyup = (e: any) => (value = e.target.value);
</script>

<div class="bno-form-control {classes}" {style}>
    {#if hintOpened}
        <mark class="bno-form-control__message">{hint}</mark>
    {/if}
    <slot name="errors" />
    {#if type == "text"}
        <input
            class="bno-form-control__input"
            {id}
            bind:value
            type="text"
            {title}
            {disabled}
            {placeholder}
            {name}
            {maxlength}
            on:blur={onBlur}
            on:keyup={onKeyup}
            aria-required={required}
            aria-disabled={disabled}
        />
    {:else}
        {type}
    {/if}

    {#if label}
        <label class="bno-form-control__label {labelClass}" for={id}>
            {label}
            {#if hint}
                <span
                    class="bno-icon bno-icon--16x16 bno-icon--info"
                    on:click|preventDefault={() => (hintOpened = !hintOpened)}
                />
            {/if}
        </label>
    {/if}
</div>
