<script>
    import { getContext } from "svelte";

    const { errors } = getContext("form");
    export let message = null;
    export let fieldName;
    export let errorKey;
</script>

{#if $errors?.[fieldName]?.[errorKey]?.error}
    <p class="bno-form-control__message bno-form-control__message-error">
        {message || $errors[fieldName][errorKey].message}
    </p>
{/if}

<style>
    .bno-form-control__message-error {
        color: var(--bno-color-red);
    }
</style>
