import merge from "lodash/merge";
export default class AppModule {
    constructor(options) {
        this._options = options;
    }
    // RUNNER
    mainSetup() {
        this._prepareOptions();
        this._verifyTargetContainer();
    }
    // OPTIONS
    _verifyTargetContainer() {
        const { el } = this._options;
        if (!(el instanceof HTMLElement)) {
            throw new Error("The container for displaying module does not exist");
        }
    }
    _prepareOptions() {
        this.commonOptions = merge({}, {
            el: null
        }, this._options);
    }
    // GETTERS
    get targetContainer() {
        return this.commonOptions.el;
    }
}
