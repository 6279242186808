import { writable, get } from 'svelte/store';
export const PER_PAGE = 20;
export default class PagerService {
    constructor() {
        this._pager = writable({
            total: 0,
            page: 1,
            perPage: PER_PAGE,
            maxPages: 5,
        });
    }
    set total(total) {
        this._pager.set(Object.assign(Object.assign({}, this.pager), { total }));
    }
    set page(page) {
        this._pager.set(Object.assign(Object.assign({}, this.pager), { page }));
    }
    set perPage(perPage) {
        this._pager.set(Object.assign(Object.assign({}, this.pager), { perPage }));
    }
    // GETTERS
    get pagerSubs() {
        return this._pager;
    }
    get pager() {
        return get(this._pager);
    }
}
