<script lang="ts">
    import { onMount } from "svelte";
    import { createEventDispatcher } from "svelte";
    import LocalDevelopmentUtils from "../../../utils/local-development-utils";
    import { modalToggled } from "../../../utils/modal-utils";
    import type { Service } from "../providers/service.provider";
    import type { Proposal } from "../services/detail.service";
    import type { ZaznamHlasovani } from "../services/detail.service";
    import type { VoteData } from "../services/vote.service";
    import type { NavrhovatelKontaktovani } from "../services/contact.service";
    import type { Data } from "../providers/data.provider";
    import ContactDialog from "./contact-dialog.svelte";

    export let proposal: Proposal;
    export let serviceProvider: Service;
    export let dataProvider: Data;
    export let votes: ZaznamHlasovani[] = [];

    const dispatch = createEventDispatcher();

    let kontaktniForm: NavrhovatelKontaktovani = {
        emailOdesilatele: null,
        zpravaText: null,
    };

    let modalOpenedPodporit: boolean = false;
    let modalOpenedOdmitnout: boolean = false;
    let modalOpenedKontaktovat: boolean = false;
    let hlasovaniAno: boolean = false;
    let hlasovaniNe: boolean = false;
    let hlasovalAno: boolean = false;
    let hlasovalNe: boolean = false;
    let hlasoval: boolean = true;
    let success: boolean = false;
    let processing: boolean = false;
    let error: boolean = false;
    let errorVoting: boolean = false;
    let canVote: boolean = false;

    let signedIn: boolean = false;

    $: modalToggled(modalOpenedPodporit);
    $: modalToggled(modalOpenedOdmitnout);
    $: modalToggled(modalOpenedKontaktovat);

    serviceProvider.detailService.votesSubs.subscribe((data) => {
        votes = data;

        if (votes && votes.length) {
            hlasovaniAno =
                proposal &&
                proposal.veLhutePodpory &&
                proposal.stav &&
                proposal.stav.sbiraPodporu &&
                !getHlasovani(votes, true);
            hlasovaniNe =
                proposal &&
                proposal.veLhutePodpory &&
                proposal.stav &&
                proposal.stav.sbiraPodporu &&
                !getHlasovani(votes, false);

            hlasovalAno = getHlasovani(votes, true);
            hlasovalNe = getHlasovani(votes, false);
            hlasoval = false;

            let action = localStorage.getItem("action");
            let parsedAction = action ? JSON.parse(action) : null;
            let timestamp = new Date().getTime();

            if (action) {
                if (
                    parsedAction.action == "podporit" &&
                    parsedAction.interval > timestamp
                ) {
                    if (hlasovaniAno) {
                        modalOpenedPodporit = true;
                    }

                    localStorage.removeItem("action");
                } else if (
                    parsedAction.action == "odmitnout" &&
                    parsedAction.interval > timestamp
                ) {
                    if (hlasovaniNe) {
                        modalOpenedOdmitnout = true;
                    }

                    localStorage.removeItem("action");
                }
            }
        } else {
            hlasoval = false;
            hlasovaniAno =
                proposal &&
                proposal.veLhutePodpory &&
                proposal.stav &&
                proposal.stav.sbiraPodporu;
            hlasovaniNe =
                proposal &&
                proposal.veLhutePodpory &&
                proposal.stav &&
                proposal.stav.sbiraPodporu;
            hlasovalAno = false;
            hlasovalNe = false;

            let action = localStorage.getItem("action");
            let parsedAction = action ? JSON.parse(action) : null;
            let timestamp = new Date().getTime();
            let signedIn = document.body.classList.contains("signed-in")
                ? true
                : false;

            if (action) {
                if (
                    signedIn &&
                    parsedAction.action == "podporit" &&
                    parsedAction.interval > timestamp
                ) {
                    if (hlasovaniAno) {
                        modalOpenedPodporit = true;
                    }

                    localStorage.removeItem("action");
                } else if (
                    signedIn &&
                    parsedAction.action == "odmitnout" &&
                    parsedAction.interval > timestamp
                ) {
                    if (hlasovaniNe) {
                        modalOpenedOdmitnout = true;
                    }

                    localStorage.removeItem("action");
                } else {
                    localStorage.removeItem("action");
                }
            }
        }
    });

    onMount(() => {
        hlasovaniAno = false;
        hlasovaniNe = false;
        hlasoval = true;
        signedIn = document.body.classList.contains("signed-in") ? true : false;

        initContact();

        if (signedIn) {
            if (!LocalDevelopmentUtils.getLocalDevelopment()) {
                serviceProvider.voteService
                    .canVote()
                    .then((result: any) => {
                        canVote = result ? result.data : false;
                        serviceProvider.detailService.loadVotes(
                            dataProvider.options.proposalId
                        );
                    })
                    .catch(() => {
                        errorVoting = true;
                        canVote = false;
                        serviceProvider.detailService.loadVotes(
                            dataProvider.options.proposalId
                        );
                    })
                    .finally(() => {});
            } else {
                canVote = true;
                hlasoval = false;
                hlasovaniAno =
                    proposal &&
                    proposal.veLhutePodpory &&
                    proposal.stav &&
                    proposal.stav.sbiraPodporu;
                hlasovaniNe =
                    proposal &&
                    proposal.veLhutePodpory &&
                    proposal.stav &&
                    proposal.stav.sbiraPodporu;
                hlasovalAno = false;
                hlasovalNe = false;
            }
        } else {
            canVote = true;
            hlasoval = false;
            hlasovaniAno =
                proposal &&
                proposal.veLhutePodpory &&
                proposal.stav &&
                proposal.stav.sbiraPodporu
                    ? true
                    : false;
            hlasovaniNe =
                proposal &&
                proposal.veLhutePodpory &&
                proposal.stav &&
                proposal.stav.sbiraPodporu
                    ? true
                    : false;
        }
    });

    function initContact() {
        kontaktniForm = {
            emailOdesilatele: null,
            zpravaText: null,
        };
    }

    function getHlasovani(
        hlasovani: ZaznamHlasovani[],
        vysledek: boolean
    ): boolean {
        let result = false;

        if (hlasovani && hlasovani.length) {
            hlasovani.forEach((item: ZaznamHlasovani) => {
                if (item && item.podpora == vysledek) {
                    result = true;
                }
            });
        }

        return result;
    }

    function onPrihlasitPodpora(e: any) {
        localStorage.setItem(
            "action",
            JSON.stringify({
                action: "podporit",
                interval: new Date().getTime() + 60000,
            })
        );

        modalOpenedPodporit = false;

        window.location.href = "/c/portal/login";
    }

    function onOdmitnoutPodpora(e: any) {
        localStorage.setItem(
            "action",
            JSON.stringify({
                action: "odmitnout",
                interval: new Date().getTime() + 60000,
            })
        );

        modalOpenedOdmitnout = false;

        window.location.href = "/c/portal/login";
    }

    function onOdmitnout(e: any) {
        error = false;
        success = false;
        processing = true;

        let voteData: VoteData = {
            hlasovani: false,
            navrhId: dataProvider.options.proposalId,
        };

        serviceProvider.voteService
            .vote(dataProvider.options.proposalId, voteData)
            .then(() => {
                success = true;
                hlasovalNe = true;
                dispatch("voteSuccess", 0);
            })
            .catch(() => (error = true))
            .finally(() => {
                processing = false;
            });
    }

    function onSchvalit(e: any) {
        error = false;
        success = false;
        processing = true;

        let voteData: VoteData = {
            hlasovani: true,
            navrhId: dataProvider.options.proposalId,
        };

        serviceProvider.voteService
            .vote(dataProvider.options.proposalId, voteData)
            .then(() => {
                success = true;
                hlasovalAno = true;
                dispatch("voteSuccess", 1);
            })
            .catch(() => (error = true))
            .finally(() => {
                processing = false;
            });
    }
</script>

{#if proposal}
    <div class="bno-container bno-page-bar bno-page-bar--compact">
        <div class="bno-container__content">
            <div class="bno-flexbox bno-page-bar__content">
                <address class="bno-address-list">
                    <ul class="bno-address-list__items">
                        <li class="bno-address-list__item">
                            <span class="bno-icon bno-icon--facebook" />
                            <a
                                class="bno-link--without-external-icon"
                                target="_blank"
                                href="https://www.facebook.com/share.php?u={window
                                    .location.href}">Sdílet návrh</a
                            >
                        </li>
                        {#if proposal.kontaktovatNavrhovatele}
                            <li class="bno-address-list__item u-pt--unset">
                                <span class="bno-icon bno-icon--user" />
                                <a
                                    href={"#"}
                                    class="bno-modal__trigger"
                                    data-id="modal-kontaktovat"
                                    on:click|preventDefault={() => {
                                        success = false;
                                        error = false;
                                        initContact();
                                        modalOpenedKontaktovat = true;
                                    }}
                                    >Kontaktovat navrhovatele</a
                                >
                            </li>
                        {/if}
                    </ul>
                </address>

                {#if modalOpenedKontaktovat}
                    <ContactDialog
                        {success}
                        {error}
                        {kontaktniForm}
                        proposalId={dataProvider.options.proposalId}
                        {serviceProvider}
                        bind:modalOpenedKontaktovat
                    />
                {/if}
            </div>

            <div
                class="bno-flexbox bno-flexbox--gap-16 bno-flexbox--justify-end bno-page-bar__aside"
            >
                <button
                    class="bno-button bno-button--primary bno-modal__trigger"
                    data-id="modal-podporit"
                    disabled={hlasoval || !hlasovaniAno || hlasovalAno}
                    on:click={() => {
                        success = false;
                        error = false;
                        modalOpenedPodporit = true;
                    }}
                >
                    {#if hlasovalAno}
                        Podpořeno
                    {:else}
                        Podpořit návrh
                    {/if}
                </button>
                <button
                    class="bno-button bno-button--secondary bno-modal__trigger"
                    data-id="modal-odmitnout"
                    disabled={hlasoval || !hlasovaniNe || hlasovalNe}
                    on:click={() => {
                        success = false;
                        error = false;
                        modalOpenedOdmitnout = true;
                    }}
                >
                    {#if hlasovalNe}
                        Odmítnuto
                    {:else}
                        Odmítnout návrh
                    {/if}
                </button>

                {#if modalOpenedPodporit}
                    <div
                        id="modal-podporit"
                        class="bno-modal is-active is-visible"
                    >
                        <div class="bno-modal__content">
                            <div class="bno-modal__header">
                                <h2>Podpořit návrh</h2>
                            </div>
                            <div class="bno-modal__content-inner">
                                {#if signedIn}
                                    {#if canVote}
                                        {#if !success}
                                            <p>
                                                Opravdu si přejete podpořit
                                                návrh {proposal.nazev}?
                                            </p>
                                        {/if}

                                        {#if success || error}
                                            <div
                                                class="bno-form-message"
                                                class:bno-form-message--success={success}
                                                class:bno-form-message--error={error}
                                            >
                                                {#if success}
                                                    <p>
                                                        Návrh byl úspěšně
                                                        podpořen.
                                                    </p>
                                                {/if}

                                                {#if error}
                                                    <p>
                                                        Návrh se nepodařilo
                                                        podpořit.
                                                    </p>
                                                {/if}
                                            </div>
                                        {/if}

                                        <div
                                            class="bno-content-wrap bno-content-wrap--buttons"
                                        >
                                            <button
                                                class="bno-button bno-button--primary"
                                                disabled={hlasoval ||
                                                    processing ||
                                                    hlasovalAno}
                                                on:click={onSchvalit}
                                            >
                                                {#if processing}
                                                    Podpořuji návrh...
                                                {:else if hlasovalAno}
                                                    Podpořeno
                                                {:else}
                                                    Ano
                                                {/if}
                                            </button>

                                            <button
                                                class="bno-button bno-button--secondary"
                                                disabled={processing}
                                                on:click={() =>
                                                    (modalOpenedPodporit = false)}
                                            >
                                                {#if hlasovalAno || hlasoval}
                                                    Zavřít
                                                {:else}
                                                    Ne
                                                {/if}
                                            </button>
                                        </div>
                                    {:else}
                                        <h3 class="text-left">
                                            Váš účet nesplňuje podmínky
                                            Občanských návrhů <br />(věk, trvalý
                                            pobyt, full účet Brno iD)
                                        </h3>

                                        <p class="text-left">
                                            Pro přihlášení do aplikace je nutné,
                                            aby byl Váš účet Brno iD ověřen
                                            pomocí osobních údajů, bylo Vám více
                                            než 18 let a měl/a jste trvalé
                                            bydliště ve městě Brně.
                                        </p>

                                        <p class="text-left">
                                            Ověření osobních údajů lze provést
                                            na některém z kontaktních míst nebo
                                            přes online ověření za pomocí
                                            nahrání občanského průkazu.
                                        </p>

                                        <p class="text-left">
                                            Pro další informace navštivte web
                                            <a
                                                class="bno-link--without-external-icon"
                                                href="https://www.brnoid.cz/cs/cms/28-tipy"
                                                target="_blank"
                                                >www.brnoid.cz/tipy</a
                                            >.
                                        </p>

                                        <div
                                            class="bno-content-wrap bno-content-wrap--buttons text-left"
                                        >
                                            <button
                                                class="bno-button bno-button--secondary"
                                                disabled={processing}
                                                on:click={() =>
                                                    (modalOpenedPodporit = false)}
                                            >
                                                OK
                                            </button>
                                        </div>
                                    {/if}
                                {:else}
                                    <p>
                                        Před podpořením návrhu {proposal.nazev} se
                                        musíte přihlásit přes Brno iD.
                                    </p>

                                    <div
                                        class="bno-content-wrap bno-content-wrap--buttons"
                                    >
                                        <button
                                            class="bno-button bno-button--primary"
                                            disabled={processing}
                                            on:click={onPrihlasitPodpora}
                                        >
                                            Přihlásit se
                                        </button>
                                        <button
                                            class="bno-button bno-button--secondary"
                                            disabled={processing}
                                            on:click={() =>
                                                (modalOpenedPodporit = false)}
                                        >
                                            Zavřít
                                        </button>
                                    </div>
                                {/if}
                            </div>

                            <button
                                class="bno-button bno-modal__close"
                                aria-labelledby="modal-close"
                                on:click={() => (modalOpenedPodporit = false)}
                            >
                                <span
                                    id="modal-close"
                                    class="bno-button__label u-sr-only"
                                >
                                    Zavřít modální okno
                                </span>
                            </button>
                        </div>

                        <div
                            class="bno-modal__backdrop"
                            on:click={() => (modalOpenedPodporit = false)}
                        />
                    </div>
                {/if}

                {#if modalOpenedOdmitnout}
                    <div
                        id="modal-odmitnout"
                        class="bno-modal is-active is-visible"
                    >
                        <div class="bno-modal__content">
                            <div class="bno-modal__header">
                                <h2>Odmítnout návrh</h2>
                            </div>
                            <div class="bno-modal__content-inner">
                                {#if signedIn}
                                    {#if canVote}
                                        {#if !success}
                                            <p>
                                                Opravdu si přejete odmítnout
                                                návrh {proposal.nazev}?
                                            </p>
                                        {/if}

                                        {#if success || error}
                                            <div
                                                class="bno-form-message"
                                                class:bno-form-message--success={success}
                                                class:bno-form-message--error={error}
                                            >
                                                {#if success}
                                                    <p>
                                                        Návrh byl úspěšně
                                                        odmítnut.
                                                    </p>
                                                {/if}

                                                {#if error}
                                                    <p>
                                                        Návrh se nepodařilo
                                                        odmítnout.
                                                    </p>
                                                {/if}
                                            </div>
                                        {/if}

                                        <div
                                            class="bno-content-wrap bno-content-wrap--buttons"
                                        >
                                            <button
                                                class="bno-button bno-button--primary"
                                                disabled={hlasoval ||
                                                    processing ||
                                                    hlasovalNe}
                                                on:click={onOdmitnout}
                                            >
                                                {#if processing}
                                                    Odmítám návrh...
                                                {:else if hlasovalNe}
                                                    Odmítnuto
                                                {:else}
                                                    Ano
                                                {/if}
                                            </button>

                                            <button
                                                class="bno-button bno-button--secondary"
                                                disabled={processing}
                                                on:click={() =>
                                                    (modalOpenedOdmitnout = false)}
                                            >
                                                {#if hlasovalNe || hlasoval}
                                                    Zavřít
                                                {:else}
                                                    Ne
                                                {/if}
                                            </button>
                                        </div>
                                    {:else}
                                        <h3 class="text-left">
                                            Váš účet nesplňuje podmínky
                                            Občanských návrhů <br />(věk, trvalý
                                            pobyt, full účet Brno iD)
                                        </h3>

                                        <p class="text-left">
                                            Pro přihlášení do aplikace je nutné,
                                            aby byl Váš účet Brno iD ověřen
                                            pomocí osobních údajů, bylo Vám více
                                            než 18 let a měl/a jste trvalé
                                            bydliště ve městě Brně.
                                        </p>

                                        <p class="text-left">
                                            Ověření osobních údajů lze provést
                                            na některém z kontaktních míst nebo
                                            přes online ověření za pomocí
                                            nahrání občanského průkazu.
                                        </p>

                                        <p class="text-left">
                                            Pro další informace navštivte web
                                            <a
                                                class="bno-link--without-external-icon"
                                                href="https://www.brnoid.cz/cs/cms/28-tipy"
                                                target="_blank"
                                                >www.brnoid.cz/tipy</a
                                            >.
                                        </p>

                                        <div
                                            class="bno-content-wrap bno-content-wrap--buttons text-left"
                                        >
                                            <button
                                                class="bno-button bno-button--secondary"
                                                disabled={processing}
                                                on:click={() =>
                                                    (modalOpenedOdmitnout = false)}
                                            >
                                                OK
                                            </button>
                                        </div>
                                    {/if}
                                {:else}
                                    <p>
                                        Před odmítnutím návrhu {proposal.nazev} se
                                        musíte přihlásit přes Brno iD.
                                    </p>

                                    <div
                                        class="bno-content-wrap bno-content-wrap--buttons"
                                    >
                                        <button
                                            class="bno-button bno-button--primary"
                                            disabled={processing}
                                            on:click={onOdmitnoutPodpora}
                                        >
                                            Přihlásit se
                                        </button>
                                        <button
                                            class="bno-button bno-button--secondary"
                                            disabled={processing}
                                            on:click={() =>
                                                (modalOpenedOdmitnout = false)}
                                        >
                                            Zavřít
                                        </button>
                                    </div>
                                {/if}
                            </div>

                            <button
                                class="bno-button bno-modal__close"
                                aria-labelledby="modal-close"
                                on:click={() => (modalOpenedOdmitnout = false)}
                            >
                                <span
                                    id="modal-close"
                                    class="bno-button__label u-sr-only"
                                >
                                    Zavřít modální okno
                                </span>
                            </button>
                        </div>

                        <div
                            class="bno-modal__backdrop"
                            on:click={() => (modalOpenedOdmitnout = false)}
                        />
                    </div>
                {/if}
            </div>
        </div>
    </div>
{/if}

<style>
    .bno-modal__content-inner {
        overflow-y: auto;
    }

    .text-left {
        text-align: left;
    }
</style>
