<script lang="ts">
</script>

<main tabindex="-1" id="bno-main">
    <header class="bno-hero-banner bno-hero-banner--height-auto">
        <div class="bno-container">
            <div class="bno-container__content">
                <h1 class="is-plain">404 - Stránka nenalezena</h1>
            </div>
        </div>
    </header>
    <div class="bno-main">
        <div class="bno-container">
            <div class="bno-container__content">
                <div class="bno-layout bno-layout--ratio-4-1">
                    <div class="bno-layout__col bno-layout__col--spread-3">
                        <p class="bno-perex">
                            Je nám líto, ale požadovaná stránka byla smazána nebo
                            přesunuta.<br />
                        </p>
                        <button
                            class="bno-button bno-button--primary"
                            on:click={() => (window.location.href = "/")}
                        >
                            Přejít na úvodní stranu
                        </button>
                    </div>

                    <div class="bno-layout__col" />
                </div>
            </div>
        </div>
    </div>
</main>
