<script lang="ts">
    import type { Service } from "./providers/service.provider";
    import type { StatistikaGalerie } from "./services/list.service";

    export let serviceProvider: Service;

    let statistics: StatistikaGalerie = null;
    let processing: boolean = false;
    let error: boolean = false;

    serviceProvider.listService.statisticsSubs.subscribe(
        (data) => (statistics = data)
    );

    serviceProvider.listService.processingStatisticsSubs.subscribe(
        (status) => (processing = status)
    );

    serviceProvider.listService.errorStatisticsSubs.subscribe(
        (status) => (error = status)
    );
</script>

{#if processing}
    <p>Načítám...</p>
{:else if statistics}
    <ul class="bno-statsbar">
        <li class="bno-statsbar__item" title="Počet unikátních hlasujících">
            <span class="bno-statsbar__num">{statistics.pocetHlasujicich}</span>

            <span
                class="bno-icon bno-icon--users-outline"
                aria-label="Počet unikátních hlasujících"
            />
        </li>
        <li
            class="bno-statsbar__item"
            title="Počet návrhů zobrazených v galerii"
        >
            <span class="bno-statsbar__num"
                >{statistics.pocetNavrhuGalerie}</span
            >

            <span
                class="bno-icon bno-icon--comment-outline"
                aria-label="Počet návrhů zobrazených v galerii"
            />
        </li>
        <li class="bno-statsbar__item" title="Počet získaných podpor">
            <span class="bno-statsbar__num">{statistics.pocetSouhlasu}</span>

            <span
                class="bno-icon bno-icon--thumbs-up-outline"
                aria-label="Počet získaných podpor"
            />
        </li>
        <li class="bno-statsbar__item" title="Počet získaných odmítnutí">
            <span class="bno-statsbar__num">{statistics.pocetOdmitnuti}</span>

            <span
                class="bno-icon bno-icon--thumbs-down-outline"
                aria-label="Počet získaných odmítnutí"
            />
        </li>
    </ul>
{/if}
