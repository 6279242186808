<script lang="ts">
    import { setContext, onMount } from "svelte";
    import Pager from "../../common/Pager/pager.svelte";
    import type { Service } from "./providers/service.provider";
    import type { Proposal } from "./services/list.service";
    import BrowserUtils from "../../utils/browser-utils";

    export let serviceProvider: Service;

    let proposalList: Proposal[] = [];
    let processing: boolean = false;
    let error: boolean = false;
    let processingSeznam: boolean = false;

    window.addEventListener(
        "popstate",
        function (event: any) {
            if (
                window.location.hostname == "localhost" ||
                window.location.pathname == "/galerie-navrhu"
            ) {
                if (window.location.search) {
                    let queryParams: any = BrowserUtils.parseQueryParams(
                        window.location
                    );

                    if (queryParams.strana && !isNaN(queryParams.strana)) {
                        serviceProvider.listService.setPage(
                            parseInt(queryParams.strana)
                        );
                    } else {
                        serviceProvider.listService.setPage(1);
                    }
                } else {
                    serviceProvider.listService.setPage(1);
                }
            }
        },
        false
    );

    onMount(() => {
        processingSeznam = true;
    });

    serviceProvider.listService.proposalSubs.subscribe(
        (list) => (proposalList = list)
    );
    serviceProvider.listService.processingSubs.subscribe((status) => {
        processing = status;

        if (!status) {
            processingSeznam = false;
        }
    });
    serviceProvider.listService.errorSubs.subscribe(
        (status) => (error = status)
    );

    setContext("pager", {
        goToPage: (page: number) => serviceProvider.listService.goToPage(page),
        pager: serviceProvider.listService,
    });
</script>

{#if processingSeznam}
    <p>Načítám...</p>
{:else if proposalList.length}
    <table class="bno-table bno-table--tablet-block bno-table--bordered">
        <thead>
            <tr>
                <th>Návrh</th>
                <th>Navrhovatel</th>
                <th>Důvod zamítnutí</th>
            </tr>
        </thead>

        <tbody>
            {#each proposalList as proposal}
                <tr>
                    <td data-category="Návrh">{proposal.nazev}</td>
                    {#if proposal.navrhovatel && proposal.navrhovatel.jmeno && proposal.navrhovatel.prijmeni}
                        <td data-category="Navrhovatel"
                            >{proposal.navrhovatel.jmeno}
                            {proposal.navrhovatel.prijmeni}</td
                        >
                    {:else}
                        <td data-category="Navrhovatel">-</td>
                    {/if}
                    <td data-category="Důvod zamítnutí" class="like-pre"
                        >{proposal.duvodZamitnuti || "-"}</td
                    >
                </tr>
            {/each}
        </tbody>
    </table>
    <Pager />
{:else if error}
    <h3>Chyba</h3>
    <p>Nastala chyba.</p>
{:else}
    <p>Nejsou k dispozici žádné nevhodné návrhy.</p>
{/if}

<style>
    .like-pre {
        white-space: -moz-pre-wrap;

        /* Opera 4-6 */
        white-space: -pre-wrap;

        /* Opera 7 */
        white-space: -o-pre-wrap;

        /* css-3 */
        white-space: pre-wrap;

        /* Internet Explorer 5.5+ */
        word-wrap: break-word;
    }
</style>
