export default new class {
    parseQueryParams(location) {
        let queryParams = {};
        if (location) {
            let locationParts = [];
            if (location.search) {
                locationParts = location.search.split("?");
            }
            else if (location.hash) {
                locationParts = location.hash.split("?");
            }
            if (locationParts && locationParts.length > 1) {
                const queryParamsParts = locationParts[1].split("&");
                if (queryParamsParts && queryParamsParts.length > 0) {
                    queryParamsParts.forEach((queryParam) => {
                        if (queryParam) {
                            var queryParts = queryParam.split("=");
                            if (queryParts && queryParts.length > 0) {
                                if (queryParts.length == 1) {
                                    queryParams[queryParts[0].trim()] = true;
                                }
                                else {
                                    queryParams[queryParts[0].trim()] = queryParts[1].trim();
                                }
                            }
                        }
                    });
                }
            }
        }
        return queryParams;
    }
};
