<script lang="ts">
    import type { Service } from "./providers/service.provider";
    import type { NewsletterData } from "./services/create.service";
    import Input from "../../components/Form/Input.svelte";
    import Checkbox from "../../components/Form/Checkbox.svelte";
    import { Validators } from "../../components/Form/Validators";
    import type { Errors, FormData } from "../../components/Form/Form";
    import FormContainer from "../../components/Form/FormContainer.svelte";
    import ErrorMessage from "../../components/Form/ErrorMessage.svelte";

    export let serviceProvider: Service;

    let formEl: any;
    let errors: Errors = {};
    let form: FormData = {
        emailOdberatele: {
            validators: [Validators.required, Validators.emailValid],
        },
        gdprSouhlas: { validators: [Validators.required] },
    };

    let newsletter: NewsletterData = {
        emailOdberatele: null,
        gdprSouhlas: false,
    };

    let success: boolean = false;
    let processing: boolean = false;
    let error: string = null;
    let errorMsgs: object = {
        ERR_EMAIL_JIZ_V_EVIDENCI:
            "E-mailová adresa již byla registrovaná pro odběr novinek z Dáme na vás. Zkontrolujte svou e-mailovou schránku.",
    };

    const onSubmit = async (e) => {
        error = null;
        processing = true;
        if (e?.detail?.valid) {
            let newsletterToSave = JSON.parse(JSON.stringify(newsletter));

            newsletterToSave.emailOdberatele =
                newsletter.emailOdberatele && newsletter.emailOdberatele.trim()
                    ? newsletter.emailOdberatele.trim()
                    : undefined;
            serviceProvider.createService
                .create(newsletterToSave)
                .then(() => {
                    success = true;
                    newsletter.emailOdberatele = null;
                    newsletter.gdprSouhlas = false;
                })
                .catch((err) => {
                    error =
                        (err &&
                            err.response &&
                            err.response.data &&
                            errorMsgs[(<any>err.response.data).errorSubCode]) ||
                        "Došlo k neznámé chybě. Opakujte akci později.";
                })
                .finally(() => (processing = false));
        } else {
            processing = false;
        }
    };
</script>

{#if !success}
    <div style="margin-top: 1rem">
        <FormContainer
            {form}
            on:submit={onSubmit}
            bind:this={formEl}
            id="newsletterForm"
            ariaLabel="Registrace k odběru novinek z Dáme na vás"
            classes="bno-dnv-subscription-form__content"
        >
            <Input
                bind:value={newsletter.emailOdberatele}
                name="emailOdberatele"
                labelClass="u-sr-only"
                label="E-mail"
                classes="u-align--left"
            >
                <span slot="errors">
                    <ErrorMessage
                        fieldName="emailOdberatele"
                        errorKey="required"
                        message="Zadejte e-mail"
                    />
                    <ErrorMessage
                        fieldName="emailOdberatele"
                        errorKey="emailValid"
                        message="Zadejte správný formát e-mailu."
                    />
                </span>
            </Input>

            <button
                class="bno-button bno-button--primary"
                type="submit"
                disabled={processing}
                aria-disabled={processing}
                role="button"
                aria-label="přihlásit newsletter"
                tabindex=0
            >
                {#if processing}
                    Odesílám...
                {:else}
                    Odeslat
                {/if}
            </button>

            <Checkbox bind:value={newsletter.gdprSouhlas} name="gdprSouhlas">
                <span slot="label">
                    Souhlasím se
                    <a
                        href="/zpracovani-osobnich-udaju"
                        class="bno-link bno-link--spread-max"
                        target="_blank"
                    >
                        zpracováním osobních údajů
                    </a>
                </span>
                <span slot="errors">
                    <ErrorMessage
                        fieldName="gdprSouhlas"
                        errorKey="required"
                        message="Musíte souhlasit se zpracováním osobních údajů."
                    />
                </span>
            </Checkbox>
        </FormContainer>
    </div>
    {#if error}
        <div class="bno-form-message bno-form-message--error">
            <p>{error}</p>
        </div>
    {/if}
{/if}

{#if success}
    <div class="bno-form-message bno-form-message--success">
        <p>
            Odeslání e-mailu proběhlo úspěšně. Proces registrace je nutné
            dokončit prostřednictvím zadané e-mailové adresy a potvrzením
            přiloženého odkazu.
        </p>
    </div>
{/if}

<style>
    .bno-button--primary {
        max-height: 62px;
    }
</style>
