<script lang="ts">
    import { createEventDispatcher, getContext } from "svelte";
    import type { FileSelect } from "../../services/interface";

    export let required: boolean = false;
    export let disabled: boolean = false;
    export let label: string = undefined;
    export let name: string;
    export let style: string = undefined;
    export let title: string = "";
    export let maxlength: number = 4;
    export let hint: string = "";

    let file: FileSelect = { name: null, view: null };
    let hintOpened: boolean = false;

    const id = Math.random() + "";
    const dispatch = createEventDispatcher();
    let input: HTMLInputElement = null;
    let hasAttachment = false;
    let error: string = "";

    const { onBlur } = getContext("form");

    const onFileSelected = (e: any) => {
        let target = e.target || e.srcElement;
        if (target.files.length === 0) {
            dispatch("error", false);
            return;
        }

        let image = target.files[0];

        error = "";

        let reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = (e) => {
            let view =
                typeof e.target.result === "string"
                    ? e.target.result
                    : Buffer.from(e.target.result).toString();

            view = view.split(",")[1];

            hasAttachment = true;
            file.name = image.name;
            file.view = view;

            dispatch("fileSelect", file);

            if (image && image.size > maxlength * 1024 * 1024) {
                error =
                    "Maximální dovolená velikost souboru je " +
                    maxlength +
                    " MB.";

                dispatch("error", true);
            } else if (
                image &&
                image.name &&
                !image.name.toLowerCase().endsWith(".jpg") &&
                !image.name.toLowerCase().endsWith(".jpeg") &&
                !image.name.toLowerCase().endsWith(".png")
            ) {
                error = "Nepodporovaný formát souboru.";

                dispatch("error", true);
            } else {
                dispatch("error", false);
            }

            file = file;
        };
    };

    const onFileRemoved = (_e: any) => {
        hasAttachment = false;
        file.name = null;
        file.view = null;

        file = file;

        error = "";

        if (input) {
            input.value = "";
        }

        dispatch("fileSelect", file);
        dispatch("error", false);
    };
</script>

<div class="bno-form-control bno-fileinput bno-js-fileinput" {style}>
    {#if hintOpened}
        <mark class="bno-form-control__message">{hint}</mark>
    {/if}

    {#if error}
        <p class="bno-form-control__message bno-form-control__message-error">
            {error}
        </p>
    {/if}

    <div class="bno-fileinput__attachments"></div>

    {#if file && file.name}
        <ul class="brno-fileinput__attachments is-active">
            <li>Přílohy</li>
            <li>
                <a href={"#"} on:click|preventDefault={(e) => onFileRemoved(e)}
                    >{file.name}</a
                >
            </li>
        </ul>
    {/if}

    <div class="bno-fileinput__upload" {title}>
        <input
            bind:this={input}
            {id}
            {name}
            class="bno-fileinput__upload-input"
            type="file"
            aria-required={required}
            aria-disabled={disabled}
            accept=".jpg, .jpeg, .png"
            on:change={(e) => onFileSelected(e)}
        />

        <div class="bno-fileinput__upload-content">
            <p class="bno-fileinput__upload-copy">Přetáhněte soubor nebo</p>
            <label
                class="bno-button bno-button--secondary bno-fileinput__upload-btn"
                for={id}
            >
                Nahrajte ze zařízení
            </label>
            <p class="bno-fileinput__upload-note">
                podporované formáty JPG, JPEG, PNG
            </p>
        </div>
    </div>

    {#if label}
        <p class="bno-fileinput__label">
            {label}
            {#if hint}
                <span
                    class="bno-icon bno-icon--16x16 bno-icon--info"
                    on:click={() => (hintOpened = !hintOpened)}
                />
            {/if}
        </p>
    {/if}
</div>

<style>
    .bno-form-control__message-error {
        color: var(--bno-color-red);
        margin-left: 10px;
    }

    .brno-fileinput__attachments > li > a:after {
        content: "i";
        display: inline-block;
        position: relative;
        right: -0.5rem;
        top: 1px;
        font-family: "bno-basic-icons";
        font-size: 0.75rem;
        text-transform: none;
    }

    .brno-fileinput__attachments > li {
        margin-top: 0;
        padding: 0;
        list-style-type: none;
    }

    .brno-fileinput__attachments > li:before {
        display: none;
    }

    .brno-fileinput__attachments.is-active {
        display: block;
        list-style-type: none;
        padding: 0;
    }

    .bno-fileinput__attachments {
        display: none !important;
    }

    .brno-fileinput__attachments {
        display: none;
        margin-left: 0.625rem;
        margin-top: 1.875rem;
    }
</style>
