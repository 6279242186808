class QueryBase {
    constructor() {
        this._childQueries = [];
        this._childQueries = [];
    }
    should() {
        return this._addChildQuery(new Should());
    }
    must() {
        return this._addChildQuery(new Must());
    }
    mustNot() {
        return this._addChildQuery(new MustNot());
    }
    nested(path) {
        return this._addChildQuery(new Nested(path));
    }
    simpleString(fields, query, prefix, defaultOperatorAnd, flags) {
        this._addChildQuery(new SimpleString(fields, query, prefix, defaultOperatorAnd, flags));
        return this;
    }
    simpleStringIgnoreIfNullOrEmpty(fields, query, prefix, defaultOperatorAnd, flags) {
        if (query !== undefined && query !== null && query.length > 0) {
            this.simpleString(fields, query, prefix, defaultOperatorAnd, flags);
        }
        return this;
    }
    range(field, gte, lte) {
        this._addChildQuery(new Range(field, gte, lte));
        return this;
    }
    match(field, query) {
        this._addChildQuery(new Match(field, query));
        return this;
    }
    matchIgnoreIfNullOrEmpty(field, query) {
        if (query !== undefined && query !== null && query !== '') {
            this.match(field, query);
        }
        return this;
    }
    matchNumber(field, query) {
        query = +query;
        this.match(field, query);
        return this;
    }
    matchNumberIgnoreIfNullOrEmpty(field, query) {
        if (query || query === 0 || query === '0') {
            query = +query;
            if (query || query === 0) {
                // is not NaN after conversion
                this.match(field, query);
            }
        }
        return this;
    }
    matchAny(field, query) {
        this._addChildQuery(new MatchAny(field, query));
        return this;
    }
    matchAnyIgnoreIfNullOrEmpty(field, query) {
        if (Array.isArray(query) && query.length > 0) {
            this.matchAny(field, query);
        }
        return this;
    }
    _addChildQuery(childQuery) {
        this._childQueries.push(childQuery);
        return childQuery;
    }
    _childQueriesToJson() {
        const result = [];
        this._childQueries.forEach(child => {
            if (child) {
                result.push(child.toJson());
            }
        });
        return result;
    }
    _childQueryToJson() {
        return this._childQueries[0] ? this._childQueries[0].toJson() : undefined;
    }
}
;
class Query extends QueryBase {
    constructor() {
        super();
    }
    toJson() {
        return {
            'query': this._childQueryToJson()
        };
    }
}
;
class Must extends QueryBase {
    constructor() {
        super();
    }
    toJson() {
        return {
            'must': this._childQueriesToJson()
        };
    }
}
;
class MustNot extends QueryBase {
    constructor() {
        super();
    }
    toJson() {
        return {
            'mustNot': this._childQueriesToJson()
        };
    }
}
;
class Should extends QueryBase {
    constructor() {
        super();
    }
    toJson() {
        return {
            'should': this._childQueriesToJson()
        };
    }
}
;
class Nested extends QueryBase {
    constructor(path) {
        super();
        this._path = path;
    }
    toJson() {
        return {
            'nested': {
                'path': this._path,
                'filter': this._childQueryToJson()
            }
        };
    }
}
;
class SimpleString extends QueryBase {
    constructor(fields, query, prefix, defaultOperatorAnd, flags) {
        super();
        this._fields = fields;
        this._query = query;
        this._prefix = prefix;
        this._defaultOperatorAnd = defaultOperatorAnd;
        this._flags = flags;
    }
    toJson() {
        const q = {};
        q.simpleString = {};
        q.simpleString.fields = this._fields;
        q.simpleString.query = this._query;
        if (this._prefix) {
            q.simpleString.prefix = this._prefix;
        }
        if (this._defaultOperatorAnd) {
            q.simpleString.defaultOperatorAnd = this._defaultOperatorAnd;
        }
        if (this._flags) {
            q.simpleString.flags = this._flags;
        }
        return q;
    }
}
;
class Range extends QueryBase {
    constructor(field, gte, lte) {
        super();
        this._field = field;
        this._gte = gte;
        this._lte = lte;
    }
    toJson() {
        const q = {};
        q.range = {};
        q.range.field = this._field;
        q.range.gte = this._gte;
        q.range.lte = this._lte;
        return q;
    }
}
;
class Match extends QueryBase {
    constructor(field, query) {
        super();
        this._field = field;
        this._query = query;
    }
    toJson() {
        return {
            'match': {
                'field': this._field,
                'query': this._query
            }
        };
    }
}
;
class MatchAny extends QueryBase {
    constructor(field, query) {
        super();
        this._field = field;
        this._query = query;
    }
    toJson() {
        return {
            'matchAny': {
                'field': this._field,
                'query': this._query
            }
        };
    }
}
;
class Fragment extends QueryBase {
    constructor(fragment) {
        super();
        this._fragment = fragment;
    }
    toJson() {
        return this._fragment;
    }
}
;
export class ElasticGenerator {
    constructor(type, index, fetch) {
        this.filter = {};
        this.filter.index = index;
        this.queryObject = new Query();
        if (type) {
            this.filter.type = [];
            this.filter.type.push(type);
        }
        if (fetch && fetch.length > 0) {
            this.filter.fetch = [];
            this.filter.fetch = this.filter.fetch.concat(fetch);
        }
    }
    pagination(start, count, sort) {
        this.filter.pagination = {};
        this.filter.pagination.start = start;
        this.filter.pagination.count = count;
        if (sort && sort.length > 0) {
            this.filter.pagination.order = [];
            if (typeof sort === 'string') {
                this.filter.pagination.order.push(sort);
            }
            else {
                this.filter.pagination.order = sort;
            }
        }
    }
    aggs(aggr, field) {
        const aggs = {};
        if (aggr === 'sum') {
            const sumField = {};
            const sum = {};
            sum.field = field;
            sumField.sum = sum;
            aggs.sumField = sumField;
        }
        else if (aggr === 'min') {
            const minField = {};
            const min = {};
            min.field = field;
            minField.min = min;
            aggs.minField = minField;
        }
        else if (aggr === 'max') {
            const maxField = {};
            const max = {};
            max.field = field;
            maxField.max = max;
            aggs.maxField = maxField;
        }
        else if (aggr === 'avg') {
            const avgField = {};
            const avg = {};
            avg.field = field;
            avgField.avg = avg;
            aggs.avgField = avgField;
        }
        else if (aggr === 'count') {
            const countField = {};
            const count = {};
            count.field = field;
            countField.count = count;
            aggs.countField = countField;
        }
        this.filter.aggs = aggs;
    }
    query() {
        return this.queryObject;
    }
    getFilter() {
        const filterWithQuery = Object.assign({}, this.filter, this.queryObject.toJson());
        return filterWithQuery;
    }
}
;
