export default class ServiceProvider {
    constructor(dataService, createService) {
        this._dataService = dataService;
        this._createService = createService;
    }
    get dataService() {
        return this._dataService;
    }
    get createService() {
        return this._createService;
    }
}
