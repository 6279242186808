export default new class {
    // Format datumu
    formatDate(date, zone = false) {
        return this._defaultDateFormat(date, zone);
    }
    // Format datumu a casu
    formatDateTime(date, zone = true) {
        let result = "";
        if (date) {
            let nDate = new Date(date);
            let hours = zone ? nDate.getHours() : nDate.getUTCHours();
            let minutes = zone ? nDate.getMinutes() : nDate.getUTCMinutes();
            let seconds = zone ? nDate.getSeconds() : nDate.getUTCSeconds();
            result = this.formatDate(date, zone) + " " + (hours < 10 ? "0" : "") + hours + ":" + (minutes < 10 ? "0" : "") + minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
        }
        return result;
    }
    getYear(date) {
        let result = null;
        if (date) {
            let nDate = new Date(date);
            result = nDate.getUTCFullYear();
        }
        return result;
    }
    getMonth(date) {
        let result = null;
        if (date) {
            let nDate = new Date(date);
            result = nDate.getUTCMonth() + 1;
        }
        return result;
    }
    getDay(date) {
        let result = null;
        if (date) {
            let nDate = new Date(date);
            result = nDate.getUTCDate();
        }
        return result;
    }
    isLeapYear(year) {
        return (year % 400) ? ((year % 100) ? ((year % 4) ? false : true) : false) : true;
    }
    // Format datumu pro input
    formatDateInput(date) {
        return this._defaultDateFormat(date);
    }
    // Format datoveho intervalu
    formatDateInterval(dateFrom, dateTo, textFrom, textTo, suffix, withTime = false) {
        let result = "";
        if (dateFrom) {
            if (textFrom) {
                result += textFrom;
            }
            if (dateFrom) {
                if (withTime) {
                    result += result ? " " + this.formatDateTime(dateFrom) : this.formatDateTime(dateFrom);
                }
                else {
                    result += result ? " " + this.formatDate(dateFrom) : this.formatDate(dateFrom);
                }
            }
        }
        if (dateTo) {
            if (textTo) {
                result += result ? " " + textTo : textTo;
            }
            if (dateTo) {
                if (withTime) {
                    result += result ? " " + this.formatDateTime(dateTo) : this.formatDateTime(dateTo);
                }
                else {
                    result += result ? " " + this.formatDate(dateTo) : this.formatDate(dateTo);
                }
            }
        }
        if (dateFrom || dateTo) {
            if (suffix) {
                result += " " + suffix;
            }
        }
        return result;
    }
    getDateString(year, month, day) {
        let result = "";
        if (year.length == 1) {
            year = "000" + year;
        }
        else if (year.length == 2) {
            year = "00" + year;
        }
        else if (year.length == 3) {
            year = "0" + year;
        }
        result = year + "-";
        // at je vse na dve mista
        result += (month < 10 ? "0" : "") + month + "-";
        result += (day < 10 ? "0" : "") + day;
        return result;
    }
    // Vytvoreni Datumoveho Stringu
    makeUrlStringDate(date, zone = false) {
        let result = "";
        let month = zone ? date.getMonth() + 1 : date.getUTCMonth() + 1;
        let day = zone ? date.getDate() : date.getUTCDate();
        let year = zone ? date.getFullYear() + "" : date.getUTCFullYear() + "";
        if (year.length == 1) {
            year = "000" + year;
        }
        else if (year.length == 2) {
            year = "00" + year;
        }
        else if (year.length == 3) {
            year = "0" + year;
        }
        result = year + "-";
        // at je vse na dve mista
        result += (month < 10 ? "0" : "") + month + "-";
        result += (day < 10 ? "0" : "") + day;
        return result;
    }
    _defaultDateFormat(date, zone = false) {
        let result = "";
        if (date) {
            let nDate = new Date(date);
            let day = zone ? nDate.getDate() : nDate.getUTCDate();
            let month = zone ? nDate.getMonth() + 1 : nDate.getUTCMonth() + 1;
            let year = zone ? nDate.getFullYear() + "" : nDate.getUTCFullYear() + "";
            if (year.length == 1) {
                year = "000" + year;
            }
            else if (year.length == 2) {
                year = "00" + year;
            }
            else if (year.length == 3) {
                year = "0" + year;
            }
            // Default cs-CZ => "d.M.yyyy"
            result = day + "." + month + "." + year;
        }
        return result;
    }
    // Format volneho datumu
    formatFreeDate(date) {
        let result = "";
        let month = null;
        let day = null;
        let year = null;
        if (date) {
            if (date.length == 4) {
                year = parseInt(date);
            }
            else if (date.length == 6) {
                year = parseInt(date.substring(0, 4));
                month = parseInt((date.substring(4)));
            }
            else if (date.length == 8) {
                year = parseInt(date.substring(0, 4));
                month = parseInt(date.substring(4, 6));
                day = parseInt(date.substring(6));
            }
            // Default cs-CZ => "d.M.yyyy"
            result = day ? (day.toString() + "." + month.toString() + "." + year.toString()) : (month ? (month.toString() + "/" + year.toString()) : year.toString());
        }
        return result;
    }
    // Test Datumu
    isValidDate(value) {
        let dateWrapper = new Date(value);
        return !isNaN(dateWrapper.getUTCDate());
    }
    compareDateLater(date1, date2) {
        return new Date(date1) >= new Date(date2); // true if date1 is later
    }
    compareOnlyDates(d1, d2) {
        let _d1 = new Date(d1);
        let _d2 = new Date(d2);
        if (_d1.getUTCFullYear() == _d2.getUTCFullYear() && _d1.getUTCMonth() == _d2.getUTCMonth() && _d1.getUTCDate() == _d2.getUTCDate()) {
            return true;
        }
        else {
            return false;
        }
    }
};
