function required(value) {
    if (value === '' || value == null || value === false) {
        return { required: { error: true, message: 'Položka je povinna' } };
    }
    return { required: { error: false } };
}
function minTwoWords(value) {
    if (String(value).length !== 0) {
        const regex = new RegExp(/(\w.+\s).+/);
        if (regex.test(value)) {
            return { minTwoWords: { error: false } };
        }
        else {
            return { minTwoWords: { error: true, message: 'Zadejte minimálně dvě slova' } };
        }
    }
}
function emailValid(value) {
    if (String(value).length !== 0) {
        const regex = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
        if (regex.test(value)) {
            return { emailValid: { error: false } };
        }
        else {
            return { emailValid: { error: true, message: 'Zadejte e-mail ve správném tvaru' } };
        }
    }
}
export const Validators = {
    required,
    minTwoWords,
    emailValid
};
