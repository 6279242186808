<script lang="ts">
    import { getContext } from "svelte";

    export let required: boolean = false;
    export let disabled: boolean = false;
    export let placeholder: string = undefined;
    export let label: string = undefined;
    export let name: string;
    export let maxlength: number = 500;
    export let title: string = "";
    export let style: string = undefined;
    export let value: string | null | null = undefined;
    export let hint: string = "";

    let hintOpened: boolean = false;

    const id = Math.random() + "";

    const { onBlur } = getContext("form");
    const onKeyup = (e: any) => (value = e.target.value);
</script>

<div class="bno-form-control" {style}>
    {#if hintOpened}
        <mark class="bno-form-control__message">{hint}</mark>
    {/if}
    <slot name="errors" />
    <textarea
        class="bno-form-control__input"
        {id}
        {title}
        {disabled}
        {placeholder}
        {name}
        {maxlength}
        on:blur={onBlur}
        on:keyup={onKeyup}
        bind:value
        aria-required={required}
        aria-disabled={disabled}
    />
    {#if label}
        <label class="bno-form-control__label" for={id}>
            {label}
            {#if hint}
                <span
                    class="bno-icon bno-icon--16x16 bno-icon--info"
                    on:click|preventDefault={() => (hintOpened = !hintOpened)}
                />
            {/if}
        </label>
    {/if}
</div>
