export default class ServiceProvider {
    constructor(detailService, voteService, contactService) {
        this._detailService = detailService;
        this._voteService = voteService;
        this._contactService = contactService;
    }
    get detailService() {
        return this._detailService;
    }
    get voteService() {
        return this._voteService;
    }
    get contactService() {
        return this._contactService;
    }
}
