export default new class {
    useDiacritics(temp) {
        if (temp) {
            temp = temp.toLowerCase();
            temp = temp.replace(/ch/g, 'hžch');
            temp = temp.replace(/á/g, 'a');
            temp = temp.replace(/č/g, 'cč');
            temp = temp.replace(/ď/g, 'd');
            temp = temp.replace(/é/g, 'e');
            temp = temp.replace(/ě/g, 'e');
            temp = temp.replace(/í/g, 'i');
            temp = temp.replace(/ň/g, 'n');
            temp = temp.replace(/ó/g, 'o');
            temp = temp.replace(/ř/g, 'rř');
            temp = temp.replace(/š/g, 'sš');
            temp = temp.replace(/ť/g, 't');
            temp = temp.replace(/ú/g, 'u');
            temp = temp.replace(/ů/g, 'u');
            temp = temp.replace(/ý/g, 'y');
            temp = temp.replace(/ž/g, 'zž');
            // razeni císel za písmeny
            temp = temp.replace(/0/g, '\u20140');
            temp = temp.replace(/1/g, '\u20141');
            temp = temp.replace(/2/g, '\u20142');
            temp = temp.replace(/3/g, '\u20143');
            temp = temp.replace(/4/g, '\u20144');
            temp = temp.replace(/5/g, '\u20145');
            temp = temp.replace(/6/g, '\u20146');
            temp = temp.replace(/7/g, '\u20147');
            temp = temp.replace(/8/g, '\u20148');
            temp = temp.replace(/9/g, '\u20149');
        }
        return temp;
    }
    replaceDiacritics(text) {
        let diacritics = {
            a: "ÀÁÂÃÄÅàáâãäåĀāąĄ",
            c: "ÇçćĆčČ",
            d: "đĐďĎ",
            e: "ÈÉÊËèéêëěĚĒēęĘ",
            i: "ÌÍÎÏìíîïĪī",
            l: "łŁ",
            n: "ÑñňŇńŃ",
            o: "ÒÓÔÕÕÖØòóôõöøŌō",
            r: "řŘ",
            s: "ŠšśŚ",
            t: "ťŤ",
            u: "ÙÚÛÜùúûüůŮŪū",
            y: "ŸÿýÝ",
            z: "ŽžżŻźŹ"
        };
        if (text) {
            for (let toLetter in diacritics) {
                if (toLetter) {
                    if (diacritics.hasOwnProperty(toLetter)) {
                        for (let i = 0, ii = diacritics[toLetter].length, fromLetter, toCaseLetter; i < ii; i++) {
                            fromLetter = diacritics[toLetter][i];
                            if (text.indexOf(fromLetter) == -1) {
                                continue;
                            }
                            toCaseLetter = fromLetter == fromLetter.toUpperCase() ? toLetter.toUpperCase() : toLetter;
                            text = text.replace(new RegExp(fromLetter, "g"), toCaseLetter);
                        }
                    }
                }
            }
            text = text.split(/\s+/).join("-").toLowerCase();
        }
        return text;
    }
};
