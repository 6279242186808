<script lang="ts">
    import { getContext } from "svelte";

    export let required: boolean = false;
    export let disabled: boolean = false;
    export let label: string = undefined;
    export let name: string;
    export let style: string = undefined;
    export let value: string = undefined;

    const id = Math.random() + "";

    const { onBlur } = getContext("form");
    const onChange = (e: any) => (value = e.target.value);
</script>

<div class="bno-form-control" {style}>
    <slot name="errors" />
    <div class="bno-select">
        <select
            {id}
            {name}
            {disabled}
            on:blur={onBlur}
            on:change={onChange}
            aria-required={required}
            aria-disabled={disabled}
        >
            <slot />
        </select>
        <label class="bno-form-control__label" for={id}>
            {label}
        </label>
    </div>
</div>
