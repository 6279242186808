export default class ServiceProvider {
    constructor(listService, dataService) {
        this._listService = listService;
        this._dataService = dataService;
    }
    get listService() {
        return this._listService;
    }
    get dataService() {
        return this._dataService;
    }
}
