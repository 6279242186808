<script lang="ts">
    import type { Service } from "./providers/service.provider";
    import type { Proposal } from "./services/list.service";
    import Card from "./components/card.svelte";

    export let serviceProvider: Service;
    export let prefix: any;

    let proposalList: Proposal[] = [];
    let processing: boolean = false;
    let error: boolean = false;

    serviceProvider.listService.proposalSubs.subscribe(
        (list) => (proposalList = list)
    );
    serviceProvider.listService.processingSubs.subscribe(
        (status) => (processing = status)
    );
    serviceProvider.listService.errorSubs.subscribe(
        (status) => (error = status)
    );
</script>

{#if processing}
    <p>Načítám...</p>
{:else if proposalList.length}
    <ul class="bno-grid bno-grid--x3 bno-list--plain">
        {#each proposalList as proposal}
            <li class="bno-grid__tile">
                <Card {proposal} {prefix} />
            </li>
        {/each}
    </ul>
{:else if error}
    <h3>Chyba</h3>
    <p>Nastala chyba.</p>
{:else}
    <p>Nejsou k dispozici žádné návrhy.</p>
{/if}
