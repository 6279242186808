<script lang="ts">
    import { setContext } from "svelte";
    import { createEventDispatcher } from "svelte";
    import type { ValidatorResult } from "./Validators";
    import type { FormData } from "./Form";
    import { writable } from "svelte/store";

    export let form: FormData = {};
    export let id: string = null;
    export let classes: string = null;
    export let ariaLabel: string = "";

    let formEl: any;

    const dispatch = createEventDispatcher();
    let errors = writable({});

    function onBlur(e: any) {
        if (e.target && e.target.type == "checkbox") {
            validateField(e.target.name, e.target.checked);
        } else if (e.target.value && typeof e.target.value === "string") {
            let value =
                e.target.value && e.target.value.trim()
                    ? e.target.value.trim()
                    : "";

            validateField(e.target.name, value);
        } else {
            validateField(e.target.name, e.target.value);
        }
    }

    function isFormValid(): boolean {
        return !Object.values($errors).some((field) =>
            Object.values(field).some(
                (errorObject: ValidatorResult) => errorObject.error
            )
        );
    }

    function validateField(field: string, value: any) {
        form[field]?.validators &&
            form[field].validators.forEach((fn) => {
                const error = fn(value);
                errors.update((e) => {
                    e[field] = { ...e[field], ...error };
                    return e;
                });
            });
    }

    function validateForm(data: { [inputName: string]: any }): void {
        Object.keys(data).forEach((field) => validateField(field, data[field]));
    }

    function onSubmit(e: any) {
        const formData = new FormData(e.target);
        const data: any = {};

        // fd.set('myCheckbox', fd.has('myCheckbox') ? '1' : '0');
        const checkboxes = e.target.querySelectorAll('input[type="checkbox"]');
        checkboxes.forEach((checkbox: HTMLInputElement) => {
            formData.set(checkbox.name, checkbox.checked.toString());
        });

        for (let field of formData) {
            const [key, value] = field;

            if (value === "false" || value === "FALSE") {
                data[key] = false;
            } else if (value === "false" || value === "FALSE") {
                data[key] = true;
            } else {
                if (typeof value === "string") {
                    data[key] = value && value.trim() ? value.trim() : "";
                } else {
                    data[key] = value;
                }
            }
        }

        validateForm(data);

        return dispatch("submit", { valid: isFormValid(), data });
    }

    export function reset() {
        formEl.reset();
    }

    setContext("form", { errors, onBlur });
</script>

<form
    on:submit|preventDefault={onSubmit}
    bind:this={formEl}
    {id}
    class={classes}
    aria-label={ariaLabel}
>
    <slot />
</form>
