import AppModule from "../module";
import DetailModule from "./module.svelte";
import DataProvider from "./providers/data.provider";
import ServiceProvider from "./providers/service.provider";
import ContactService from "./services/contact.service";
import DetailService from "./services/detail.service";
import VoteService from "./services/vote.service";
class ProposalModule extends AppModule {
    constructor(options) {
        super(options);
        this._setup();
    }
    _setup() {
        this.mainSetup();
        this.dataProvider = new DataProvider(this.options);
        this.serviceProvider = new ServiceProvider(new DetailService, new VoteService, new ContactService);
        this._initApp();
    }
    _initApp() {
        this.appInstance = new DetailModule({
            target: this.targetContainer,
            props: {
                serviceProvider: this.serviceProvider,
                dataProvider: this.dataProvider
            }
        });
    }
    // OPTIONS
    get options() {
        return this.commonOptions;
    }
}
const element = document.getElementById('app-proposal');
function checkRoutePathAndGetParams() {
    let result = null;
    let routePath = window.location.pathname;
    let routePathParts = routePath.split("/");
    if (routePathParts && routePathParts.length > 0) {
        let proposalPart = routePathParts[routePathParts.length - 1].split("-");
        result = +proposalPart[0];
        result = !isNaN(result) ? result : null;
    }
    return result;
}
if (element) {
    new ProposalModule({ el: element, proposalId: checkRoutePathAndGetParams() });
}
