<script lang="ts">
    import { setContext, onMount } from "svelte";
    import Pager from "../../common/Pager/pager.svelte";
    import type { Service } from "./providers/service.provider";
    import type { Proposal } from "./services/list.service";
    import Card from "./components/card.svelte";
    import BrowserUtils from "../../utils/browser-utils";

    export let serviceProvider: Service;
    export let prefix: any;

    let proposalList: Proposal[] = [];
    let processing: boolean = false;
    let error: boolean = false;
    let processingSeznam: boolean = false;

    window.addEventListener(
        "popstate",
        function (event: any) {
            if (
                window.location.hostname == "localhost" ||
                window.location.pathname == "/galerie-navrhu"
            ) {
                if (window.location.search) {
                    let queryParams: any = BrowserUtils.parseQueryParams(
                        window.location
                    );

                    if (queryParams.strana && !isNaN(queryParams.strana)) {
                        serviceProvider.listService.setPage(
                            parseInt(queryParams.strana)
                        );
                    } else {
                        serviceProvider.listService.setPage(1);
                    }
                } else {
                    serviceProvider.listService.setPage(1);
                }
            }
        },
        false
    );

    onMount(() => {
        processingSeznam = true;
    });

    serviceProvider.listService.proposalSubs.subscribe(
        (list) => (proposalList = list)
    );
    serviceProvider.listService.processingSubs.subscribe((status) => {
        processing = status;

        if (!status) {
            processingSeznam = false;
        }
    });
    serviceProvider.listService.errorSubs.subscribe(
        (status) => (error = status)
    );

    setContext("pager", {
        goToPage: (page: number) => serviceProvider.listService.goToPage(page),
        pager: serviceProvider.listService,
    });
</script>

{#if processingSeznam}
    <p>Načítám...</p>
{:else if proposalList && proposalList.length}
    <ul class="bno-grid bno-grid--x4 bno-list--plain">
        {#each proposalList as proposal}
            <li class="bno-grid__tile">
                <Card {proposal} {prefix} />
            </li>
        {/each}
    </ul>
    <Pager />
{:else if error}
    <h3>Chyba</h3>
    <p>Nastala chyba.</p>
{:else}
    <p>Nejsou k dispozici žádné návrhy.</p>
{/if}
