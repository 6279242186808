export default new class {
    vypisCiselnikovuPolozku(ciselnik, object) {
        if (ciselnik && ciselnik.length > 0 && object) {
            for (let i = 0; i < ciselnik.length; i++) {
                if (ciselnik[i] && ciselnik[i].id === object.id) {
                    return ciselnik[i];
                }
            }
        }
        return null;
    }
    vypisCiselnikovuPolozkuById(ciselnik, objectId) {
        if (ciselnik && ciselnik.length > 0 && objectId) {
            for (let i = 0; i < ciselnik.length; i++) {
                if (ciselnik[i] && ciselnik[i].id === objectId) {
                    return ciselnik[i];
                }
            }
        }
        return null;
    }
    vypisCiselnikovuPolozkuByNazev(ciselnik, nazev) {
        if (ciselnik && ciselnik.length > 0 && nazev) {
            for (let i = 0; i < ciselnik.length; i++) {
                if (ciselnik[i] && ciselnik[i].nazev && ciselnik[i].nazev.trim() === nazev.trim()) {
                    return ciselnik[i];
                }
            }
        }
        return null;
    }
    vypisNazevCiselnikovePolozkyById(ciselnik, objectId) {
        if (ciselnik && ciselnik.length > 0 && objectId) {
            for (let i = 0; i < ciselnik.length; i++) {
                if (ciselnik[i] && ciselnik[i].id === objectId) {
                    return ciselnik[i].nazev;
                }
            }
        }
        return null;
    }
    vypisKodCiselnikovePolozkyById(ciselnik, objectId) {
        if (ciselnik && ciselnik.length > 0 && objectId) {
            for (let i = 0; i < ciselnik.length; i++) {
                if (ciselnik[i] && ciselnik[i].id === objectId) {
                    return ciselnik[i].kod;
                }
            }
        }
        return null;
    }
    vypisValueCiselnikovePolozkyById(ciselnik, objectId) {
        if (ciselnik && ciselnik.length > 0 && objectId) {
            for (let i = 0; i < ciselnik.length; i++) {
                if (ciselnik[i] && ciselnik[i].id === objectId) {
                    return ciselnik[i].value;
                }
            }
        }
        return null;
    }
    vypisCiselnikovuPolozkuByKod(ciselnik, objectKod) {
        if (ciselnik && ciselnik.length > 0 && objectKod) {
            for (let i = 0; i < ciselnik.length; i++) {
                if (ciselnik[i] && ciselnik[i].kod === objectKod) {
                    return ciselnik[i];
                }
            }
        }
        return null;
    }
    vypisIdCiselnikovePolozky(ciselnik, object) {
        if (ciselnik && ciselnik.length > 0 && object) {
            for (let i = 0; i < ciselnik.length; i++) {
                if (ciselnik[i] && ciselnik[i].id === object.id) {
                    return ciselnik[i].id;
                }
            }
        }
        return null;
    }
    vypisIdCiselnikovePolozkyByKod(ciselnik, objectKod) {
        if (ciselnik && ciselnik.length > 0 && objectKod) {
            for (let i = 0; i < ciselnik.length; i++) {
                if (ciselnik[i] && ciselnik[i].kod === objectKod) {
                    return ciselnik[i].id;
                }
            }
        }
        return null;
    }
    vypisIdsCiselnikovePolozky(ciselnik) {
        const result = [];
        if (ciselnik && ciselnik.length > 0) {
            ciselnik.forEach((polozka) => {
                if (polozka) {
                    result.push(polozka.id);
                }
            });
        }
        return result;
    }
    zmenCiselnikNaOptions(ciselnik) {
        const options = [];
        if (ciselnik && ciselnik.length > 0) {
            for (let i = 0; i < ciselnik.length; i++) {
                if (ciselnik[i]) {
                    options.push({
                        id: ciselnik[i].id,
                        popis: ciselnik[i].nazev ? ciselnik[i].nazev.trim() : ciselnik[i].nazev,
                        value: ciselnik[i].nazev ? ciselnik[i].nazev.trim() : ciselnik[i].nazev
                    });
                }
            }
        }
        return options;
    }
    zmenCiselnikNaOptionsWithElement(ciselnik) {
        const options = [];
        if (ciselnik && ciselnik.length > 0) {
            for (let i = 0; i < ciselnik.length; i++) {
                if (ciselnik[i]) {
                    options.push({
                        id: ciselnik[i].id,
                        popis: ciselnik[i].nazev ? ciselnik[i].nazev.trim() : ciselnik[i].nazev,
                        value: ciselnik[i].nazev ? ciselnik[i].nazev.trim() : ciselnik[i].nazev,
                        element: ciselnik[i]
                    });
                }
            }
        }
        return options;
    }
    zmenCiselnikNaOptionsCodeWithElement(ciselnik) {
        const options = [];
        if (ciselnik && ciselnik.length > 0) {
            for (let i = 0; i < ciselnik.length; i++) {
                if (ciselnik[i]) {
                    options.push({
                        id: ciselnik[i].kod,
                        popis: ciselnik[i].nazev && ciselnik[i].kod ? ciselnik[i].nazev.trim() + ' (' + ciselnik[i].kod + ')' : ciselnik[i].nazev,
                        value: ciselnik[i].nazev && ciselnik[i].kod ? ciselnik[i].nazev.trim() + ' (' + ciselnik[i].kod + ')' : ciselnik[i].nazev,
                        element: ciselnik[i]
                    });
                }
            }
        }
        return options;
    }
    ciselnikToOptions(polozky) {
        const options = [];
        if (polozky && polozky.length > 0) {
            polozky.forEach((polozka) => {
                if (polozka) {
                    options.push({
                        value: polozka.id,
                        label: polozka.nazev ? polozka.nazev.trim() : polozka.nazev
                    });
                }
            });
        }
        return options;
    }
    ciselnikToOptionsWithElement(polozky) {
        const options = [];
        if (polozky && polozky.length > 0) {
            polozky.forEach((polozka) => {
                if (polozka) {
                    options.push({
                        value: polozka.id,
                        label: polozka.nazev ? polozka.nazev.trim() : polozka.nazev,
                        element: polozka
                    });
                }
            });
        }
        return options;
    }
    polozkyToOptions(polozky) {
        const options = [];
        if (polozky && polozky.length > 0) {
            polozky.forEach((polozka) => {
                if (polozka) {
                    options.push({
                        id: polozka.id,
                        value: polozka.nazev ? polozka.nazev.trim() : polozka.nazev
                    });
                }
            });
        }
        return options;
    }
    polozkyZkrToOptions(polozky) {
        const options = [];
        polozky.forEach((polozka) => {
            if (polozka) {
                options.push({
                    id: polozka.id,
                    value: polozka.nazevZkr
                });
            }
        });
        return options;
    }
    polozkaToOption(polozka) {
        let result = null;
        if (polozka) {
            result = {
                id: polozka.id,
                value: polozka.nazev ? polozka.nazev.trim() : polozka.nazev
            };
        }
        return result;
    }
};
