<script lang="ts">
    import type { Proposal } from "../services/list.service";
    import Config from "../../../core/config/index";
    import CharUtils from "../../../utils/char-utils";

    export let proposal: Proposal;
    export let prefix: any = "";

    let portalUrl = window.location.origin;

    if (window.location.hostname === "localhost") {
        portalUrl = "https://smb-web-dev-navrhydamenavas.asseco-ce.com";
    }
</script>

{#if proposal}
    <a
        class="bno-preview-tile"
        href="{prefix ? prefix : ''}/w/detail-navrhu/{proposal.id}"
    >
        <!-- -{CharUtils.replaceDiacritics(
    proposal.nazev
) -->

        <div class="bno-preview-tile__header" title={proposal.nazev}>
            {#if proposal.navrhNahledPreview && proposal.navrhNahledPreview != "0"}
                <img
                    src="{portalUrl}/picfit/display?url={portalUrl}{Config.imageBaseUrl}/{proposal.navrhNahledPreview}/content&w=378&h=160&op=thumbnail"
                    alt={proposal.nazev}
                />
            {/if}
        </div>
        <div class="bno-preview-tile__content">
            <h4>
                {proposal.nazev}
            </h4>

            <div class="bno-preview-tile__labels">
                <span class="bno-label">
                    <span
                        class="bno-icon bno-icon--thumbs-up"
                        aria-label="Pozitivní hlasy"
                    />
                    {proposal.pocetHlasuPozitivnich}
                </span>

                <span class="bno-label">
                    <span
                        class="bno-icon bno-icon--thumbs-up bno-icon--direction-left"
                        aria-label="Negativní hlasy"
                    />
                    {proposal.pocetHlasuNegativnich}
                </span>
            </div>

            <div class="bno-preview-tile__tags">
                <span class="bno-chip">
                    <span class="bno-chip__inner">
                        {proposal.kategorie.nazev}
                    </span>
                </span>

                <span class="bno-chip">
                    <span class="bno-chip__inner">
                        {proposal.stav.nazevVerejny}
                    </span>
                </span>
            </div>
        </div>
    </a>
{/if}

<style>
    a {
        text-decoration: none;
    }

    a h4 {
        text-decoration: underline;
    }

    a:hover h4 {
        text-decoration: none;
    }
</style>
