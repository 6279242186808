<script lang="ts">
    import type { Service } from "../providers/service.provider";
    import Input from "../../../components/Form/Input.svelte";
    import FormContainer from "../../../components/Form/FormContainer.svelte";
    import type { Errors, FormData } from "../../../components/Form/Form";
    import ErrorMessage from "../../../components/Form/ErrorMessage.svelte";
    import Textarea from "../../../components/Form/Textarea.svelte";
    import { Validators } from "../../../components/Form/Validators";
    import { modalToggled } from "../../../utils/modal-utils";
    import type { NavrhovatelKontaktovani } from "../services/contact.service";

    export let proposalId: number;
    export let serviceProvider: Service;
    export let modalOpenedKontaktovat: boolean = false;
    export let success: boolean = false;
    export let error: boolean = false;

    export let kontaktniForm: NavrhovatelKontaktovani = {
        emailOdesilatele: null,
        zpravaText: null,
    };

    let formEl: any;
    let errors: Errors = {};
    let form: FormData = {
        email: { validators: [Validators.required, Validators.emailValid] },
        zpravaText: { validators: [Validators.required] },
    };

    let processing: boolean = false;

    $: modalToggled(modalOpenedKontaktovat);

    function onKontaktovat(e: any) {
        error = false;
        success = false;
        processing = true;

        if (e?.detail?.valid) {
            // console.log(kontaktniForm);
            let contactToSave = JSON.parse(JSON.stringify(kontaktniForm));

            serviceProvider.contactService
                .contact(proposalId, contactToSave)
                .then(() => {
                    kontaktniForm.zpravaText = "";
                    kontaktniForm.emailOdesilatele = "";
                    success = true;
                })
                .catch(() => (error = true))
                .finally(() => {
                    processing = false;
                });
        } else {
            // console.log("Invalid Form");
            processing = false;
        }
    }
</script>

<div id="modal-kontaktovat" class="bno-modal is-active is-visible">
    <div class="bno-modal__content">
        <div class="bno-modal__header">
            <h2>Kontaktní formulář</h2>
        </div>
        <FormContainer
            {form}
            on:submit={onKontaktovat}
            bind:this={formEl}
            ariaLabel="Kontaktní formulář"
        >
            <div class="bno-modal__content-inner">
                <Input
                    bind:value={kontaktniForm.emailOdesilatele}
                    name="email"
                    label="E-mail odesílatele"
                    maxlength={100}
                    style="max-width: 500px"
                >
                    <span slot="errors">
                        <ErrorMessage
                            fieldName="email"
                            errorKey="required"
                            message="Zadejte e-mail odesílatele"
                        />
                        <ErrorMessage fieldName="email" errorKey="emailValid" />
                    </span>
                </Input>

                <Textarea
                    bind:value={kontaktniForm.zpravaText}
                    name="zpravaText"
                    maxlength={2500}
                    label="Obsah zprávy"
                    style="max-width: 650px"
                >
                    <span slot="errors">
                        <ErrorMessage
                            fieldName="zpravaText"
                            errorKey="required"
                            message="Zadejte obsah zprávy"
                        />
                    </span>
                </Textarea>

                {#if success || error}
                    <div
                        class="bno-form-message"
                        class:bno-form-message--success={success}
                        class:bno-form-message--error={error}
                    >
                        {#if success}
                            <p>Navrhovatel byl úspěšně kontaktován.</p>
                        {/if}

                        {#if error}
                            <p>Navrhovatele se nepodařilo kontaktovat.</p>
                        {/if}
                    </div>
                {/if}

                <div class="bno-content-wrap bno-content-wrap--buttons">
                    <button
                        class="bno-button bno-button--primary"
                        type="submit"
                        disabled={processing || success}
                    >
                        {#if processing}
                            Odesílám...
                        {:else}
                            Odeslat
                        {/if}
                    </button>
                    <button
                        type="button"
                        class="bno-button bno-button--secondary"
                        disabled={processing}
                        on:click={() => (modalOpenedKontaktovat = false)}
                    >
                        Zavřít
                    </button>
                </div>
            </div>
        </FormContainer>

        <button
            class="bno-button bno-modal__close"
            aria-labelledby="modal-close"
            on:click={() => (modalOpenedKontaktovat = false)}
        >
            <span id="modal-close" class="bno-button__label u-sr-only">
                Zavřít modální okno
            </span>
        </button>
    </div>

    <div
        class="bno-modal__backdrop"
        on:click={() => (modalOpenedKontaktovat = false)}
    />
</div>
