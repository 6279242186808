import { get, writable } from "svelte/store";
import $axios from "../../../core/rest/axios";
import PagerService from "../../../common/Pager/pager.service";
import { ListType } from "../../../services/interface";
import { ElasticGenerator } from "../../../utils/elastic-generator";
import { updateUrlParameter } from "../../../helpers/url.helper";
import { scrollToTop, windowPushHistory } from "../../../helpers/window.helper";
export default class ListService extends PagerService {
    constructor(options) {
        super();
        this._proposals = writable([]);
        this._filter = writable({
            stavy: [],
            stav: "",
            oblast: "",
            vyhledat: "",
            type: options.type,
            pagingAndSorting: {
                start: 0,
                size: options.type === ListType.DEFAULT || options.type === ListType.BAD ? 20 : 6,
                asc: false,
                order: options.type === ListType.HOME ? "datumZverejneni" : (options.type === ListType.BAD ? "datumNevhodne" : ""),
            },
        });
        this._error = writable(false);
        this._processing = writable(false);
        this._errorStatistics = writable(false);
        this._processingStatistics = writable(false);
        this._statistics = writable(null);
        this.registerListeners();
        if (options.type === ListType.HOME) {
            this.loadStatistics();
        }
    }
    registerListeners() {
        this._filter.subscribe(() => {
            const urlPage = this.getPageParameterFromUrl();
            this.page = urlPage;
            this.load();
        });
    }
    load() {
        const filter = this.filter;
        const pager = this.pager;
        // filter.start = (pager.page - 1) * filter.size
        let elasticGenerator = new ElasticGenerator(undefined, ["enavrhy"]);
        const query = elasticGenerator.query().must();
        if (this.filter) {
            if (filter.vyhledat && filter.vyhledat.trim()) {
                query.simpleString(["nazev"], "*" + filter.vyhledat.trim() + "*", undefined, undefined, undefined);
            }
            if (filter.oblast) {
                query.match("kategorieId", filter.oblast);
            }
            if (filter.stav) {
                let stavyToCheck = [];
                filter.stavy.forEach((item) => {
                    if (item && item.id == filter.stav) {
                        stavyToCheck = item.elements;
                    }
                });
                if (stavyToCheck && stavyToCheck.length > 0) {
                    query.matchAny("stavId", stavyToCheck);
                }
            }
            let order = filter.pagingAndSorting.order ? filter.pagingAndSorting.order : undefined;
            let orderToSet = [];
            if (order) {
                if (!filter.pagingAndSorting.asc) {
                    orderToSet.push("-" + order);
                    orderToSet.push("-id");
                }
                else {
                    orderToSet.push(order);
                    orderToSet.push("id");
                }
            }
            elasticGenerator.pagination((pager.page - 1) * filter.pagingAndSorting.size, filter.pagingAndSorting.size, orderToSet);
        }
        let elasticQuery = elasticGenerator.getFilter();
        this._processing.set(true);
        if (filter.type === ListType.BAD) {
            $axios.post("/servlet/proxy/enavrhy/navrhy/nevhodne/hledat", elasticQuery)
                .then((response) => {
                this._proposals.set(response.data.list);
                this._error.set(false);
                this.total = response.data.count;
            })
                .catch(() => this._error.set(true))
                .finally(() => this._processing.set(false));
        }
        else {
            $axios.post("/servlet/proxy/enavrhy/navrhy/hledat", elasticQuery)
                .then((response) => {
                this._proposals.set(response.data.list);
                this._error.set(false);
                this.total = response.data.count;
            })
                .catch(() => this._error.set(true))
                .finally(() => this._processing.set(false));
        }
    }
    loadStatistics() {
        this._processingStatistics.set(true);
        $axios.get("/servlet/proxy/enavrhy/navrhy/statistiky")
            .then((response) => {
            this._statistics.set(response.data);
            this._errorStatistics.set(false);
        })
            .catch(() => this._errorStatistics.set(true))
            .finally(() => this._processingStatistics.set(false));
    }
    getPageParameterFromUrl() {
        const urlParams = new URLSearchParams(window.location.search);
        const page = urlParams.get("strana");
        return page ? parseInt(page) : 1;
    }
    sort() {
        this.page = 1;
        windowPushHistory(updateUrlParameter("strana", 1));
        this.load();
    }
    goToPage(page) {
        this.page = page;
        windowPushHistory(updateUrlParameter("strana", page));
        scrollToTop();
        this.load();
    }
    setPage(page) {
        this.page = page;
        this.load();
    }
    // SETTERS
    set filter(data) {
        this._filter.set(data);
    }
    // GETTERS
    get statisticsSubs() {
        return this._statistics;
    }
    get processingStatisticsSubs() {
        return this._processingStatistics;
    }
    get errorStatisticsSubs() {
        return this._errorStatistics;
    }
    get proposalSubs() {
        return this._proposals;
    }
    get filterSubs() {
        return this._filter;
    }
    get processingSubs() {
        return this._processing;
    }
    get errorSubs() {
        return this._error;
    }
    get filter() {
        return get(this._filter);
    }
}
