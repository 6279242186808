<script lang="ts">
    import type { Proposal } from "../services/detail.service";

    export let proposal: Proposal;

    function formatDate(dateString: number): string {
        let date = new Date(dateString);
        let month = "" + (date.getUTCMonth() + 1);
        let day = "" + date.getUTCDate();
        let year = date.getUTCFullYear();

        if (month.length < 2) {
            month = "0" + month;
        }

        if (day.length < 2) {
            day = "0" + day;
        }

        return [day, month, year].join(".");
    }
</script>

{#if proposal}
    <h2>Deník návrhu</h2>

    <table class="bno-table bno-table--tablet-block bno-table--bordered">
        <thead>
            <tr>
                <th class="width200">Datum</th>
                <th>Popis</th>
            </tr>
        </thead>

        <tbody>
            {#each proposal.denikRealizace as denik}
                <tr>
                    <td class="width200" data-category="Datum"
                        >{formatDate(denik.datum)}</td
                    >
                    <td data-category="Popis">{denik.popis}</td>
                </tr>
            {/each}
        </tbody>
    </table>
{/if}

<style>
    .width200 {
        width: 130px;
    }

    @media (max-width: 831px) {
        .width200 {
            width: 100%;
        }
    }
</style>
