// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import pck from '../../../package.json';
const config = {
    apiBaseUrl: parse('API_BASE_URL', undefined),
    imageBaseUrl: parse('IMAGE_BASE_URL', undefined),
    mapBaseUrl: parse('MAP_BASE_URL', undefined),
    themeBaseUrl: parse('THEME_BASE_URL', undefined),
    version: pck.version
};
function parse(value, fallback) {
    if (typeof value === 'undefined' || typeof value === undefined) {
        return fallback;
    }
    switch (typeof fallback) {
        case 'boolean':
            return !!JSON.parse(value);
        case 'number':
            return JSON.parse(value);
        default:
            return value;
    }
}
export default config;
