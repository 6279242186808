import { writable } from "svelte/store";
import $axios from "../../../core/rest/axios";
export default class DetailService {
    constructor() {
        this._proposal = writable(null);
        this._error = writable(false);
        this._processing = writable(false);
        this._votes = writable([]);
        this._errorVotes = writable(false);
        this._processingVotes = writable(false);
        // this.load()
    }
    load(proposalId) {
        this._processing.set(true);
        $axios.get("/servlet/proxy/enavrhy/navrhy/" + proposalId)
            .then((response) => {
            this.loadPrilohy(proposalId)
                .then((responsePrilohy) => {
                let proposal = response.data;
                proposal.prilohy = responsePrilohy.data;
                if (proposal.denikRealizace && proposal.denikRealizace.length > 0) {
                    proposal.denikRealizace = proposal.denikRealizace.sort(this.sortByDate);
                }
                this._proposal.set(proposal);
                this._error.set(false);
            })
                .catch(() => this._error.set(true))
                .finally(() => this._processing.set(false));
        })
            .catch(() => this._error.set(true))
            .finally(() => this._processing.set(false));
    }
    sortByDate(aItem, bItem) {
        if (aItem && bItem && aItem.datum && bItem.datum) {
            var aId = aItem.datum;
            var bId = bItem.datum;
            if (aId > bId) {
                return 1;
            }
            else if (aId < bId) {
                return -1;
            }
        }
        return 0;
    }
    loadVotes(proposalId) {
        this._processingVotes.set(true);
        $axios.get("/servlet/proxy/enavrhy/navrhy/" + proposalId + "/podpora")
            .then((response) => {
            this._votes.set(response.data);
            this._errorVotes.set(false);
        })
            .catch(() => this._errorVotes.set(true))
            .finally(() => this._processingVotes.set(false));
    }
    loadPrilohy(navrhId) {
        this._processing.set(true);
        return $axios.get("/servlet/proxy/enavrhy/navrhy/" + navrhId + "/prilohy");
    }
    // GETTERS
    get votesSubs() {
        return this._votes;
    }
    get processingVotesSubs() {
        return this._processingVotes;
    }
    get errorVotesSubs() {
        return this._errorVotes;
    }
    get proposalSubs() {
        return this._proposal;
    }
    get processingSubs() {
        return this._processing;
    }
    get errorSubs() {
        return this._error;
    }
}
