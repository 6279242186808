export function updateUrlParameter(param, value, alternativeUrl = null) {
    let url = alternativeUrl || window.location.href;
    let index = url.indexOf("?");
    if (index > 0) {
        let u = url.substring(index + 1).split("&");
        let params = new Array(u.length);
        let found = false;
        for (let i = 0; i < u.length; i++) {
            params[i] = u[i].split("=");
            if (params[i][0] === param) {
                params[i][1] = value;
                found = true;
            }
        }
        if (!found) {
            params.push(new Array(2));
            params[params.length - 1][0] = param;
            params[params.length - 1][1] = value;
        }
        let res = url.substring(0, index + 1) + params[0][0] + "=" + params[0][1];
        for (let i = 1; i < params.length; i++) {
            res += "&" + params[i][0] + "=" + params[i][1];
        }
        return res;
    }
    else {
        return url + "?" + param + "=" + value;
    }
}
