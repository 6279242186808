// TODO: in future refactor to modal component?
const _bodyElement = document.querySelector('body');
const _htmlElement = document.querySelector('html');
export function modalToggled(isOpen) {
    if (isOpen) {
        revealModal();
    }
    else {
        hideModal();
    }
}
function revealModal() {
    _htmlElement.classList.add('modal-opened');
    _bodyElement.style.overflow = 'hidden';
}
function hideModal() {
    _htmlElement.classList.remove('modal-opened');
    _bodyElement.style.overflow = 'initial';
}
