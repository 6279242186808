import AppModule from "../module";
import FormModule from "./module.svelte";
import ServiceProvider from "./providers/service.provider";
import CreateService from "./services/create.service";
import DataService from "../../services/data.service";
class NewsletterModule extends AppModule {
    constructor(options) {
        super(options);
        this._setup();
    }
    _setup() {
        this.mainSetup();
        this.serviceProvider = new ServiceProvider(new DataService, new CreateService);
        this._initApp();
    }
    _initApp() {
        this.appInstance = new FormModule({
            target: this.targetContainer,
            props: {
                serviceProvider: this.serviceProvider
            }
        });
    }
}
const element = document.getElementById('app-newsletter');
if (element) {
    new NewsletterModule({ el: element });
}
