<script lang="ts">
    import type { Service } from "./providers/service.provider";
    import type { ProposalFilter } from "./services/list.service";
    import type { Area, Status } from "../../services/data.service";

    export let serviceProvider: Service;

    let areas: Area[] = [];
    let statuses: Status[] = [];

    let data: ProposalFilter = serviceProvider.listService.filter;
    serviceProvider.listService.filterSubs.subscribe(
        (filter) => (data = filter)
    );

    serviceProvider.dataService.areasSubs.subscribe((data) => (areas = data));
    serviceProvider.dataService.loadAreas();

    serviceProvider.dataService.statusesSubs.subscribe((dataStatuses) => {
        statuses = dataStatuses;
        data.stavy = dataStatuses;
    });
    serviceProvider.dataService.loadStatuses();

    function onSubmit(): void {
        data.stav = "";
        data.oblast = "";
        data.vyhledat = "";
        data.pagingAndSorting.order = "";
        data.pagingAndSorting.asc = false;

        serviceProvider.listService.sort();

        data = data;
    }

    function loadFilterSearch(): void {
        serviceProvider.listService.sort();

        data = data;
    }
</script>

<form class="bno-hero-banner__form" aria-label="Vyhledávání návrhů">
    <div class="bno-form-control">
        <div class="bno-select">
            <select
                name="stav"
                id="stav"
                bind:value={data.stav}
                on:change={loadFilterSearch}
            >
                <option value="">Všechny stavy</option>
                {#each statuses as status}
                    <option value={status.id}>{status.nazevVerejny}</option>
                {/each}
            </select>
            <label class="bno-form-control__label u-sr-only" for="stav">
                Stav
            </label>
        </div>
    </div>

    <div class="bno-form-control">
        <div class="bno-select">
            <select
                name="oblast"
                id="oblast"
                bind:value={data.oblast}
                on:change={loadFilterSearch}
            >
                <option value="">Všechny oblasti</option>
                {#each areas as area}
                    <option value={area.id}>{area.nazev}</option>
                {/each}
            </select>
            <label class="bno-form-control__label u-sr-only" for="oblast">
                Oblast
            </label>
        </div>
    </div>

    <div class="bno-search bno-search--compact">
        <div class="bno-search__inner">
            <div class="bno-form-control">
                <input
                    type="text"
                    id="search"
                    name="search"
                    class="bno-form-control__input bno-form-control__input--standalone"
                    placeholder="Vyhledat"
                    bind:value={data.vyhledat}
                    on:keyup={loadFilterSearch}
                />

                <label class="bno-form-control__label u-sr-only" for="search">
                    Hledat
                </label>
            </div>

            <button
                class="bno-toggle bno-toggle--plain"
                type="submit"
                aria-label="Vyhledat"
                on:click|preventDefault={onSubmit}
            >
                <span class="bno-icon bno-icon--search" />
            </button>
        </div>
    </div>

    {#if data && (data.oblast || data.stav || (data.vyhledat && data.vyhledat.trim()))}
        <button
            type="button"
            on:click|preventDefault={onSubmit}
            class="bno-button bno-button--secondary">Zrušit&nbsp;filtr</button
        >
    {/if}
</form>
