<script lang="ts">
    import type { Proposal } from "../services/detail.service";
    import Config from "../../../core/config/index";
    import DateUtils from "../../../utils/date-utils";

    export let proposal: Proposal;

    let portalUrl = window.location.origin;

    if (window.location.hostname === "localhost") {
        portalUrl = "https://smb-web-dev-navrhydamenavas.asseco-ce.com";
    }
</script>

{#if proposal}
    {#if proposal.navrhNahledPreview && proposal.navrhNahledPreview != "0"}
        <img
            src="{portalUrl}/picfit/display?url={portalUrl}{Config.imageBaseUrl}/{proposal.navrhNahledPreview}/content&w=380&op=resize"
            title={proposal.nazev}
            alt={proposal.nazev}
        />
    {:else}
        <div class="bno-preview-tile__header" title={proposal.nazev} />
    {/if}

    <div class="bno-box-wrap">
        <table class="bno-table bno-table--compact">
            <tbody>
                {#if proposal.navrhovatel && proposal.navrhovatel.jmeno && proposal.navrhovatel.prijmeni}
                    <tr>
                        <td><strong>Navrhovatel</strong></td>
                        <td class="u-align--right"
                            >{proposal.navrhovatel.jmeno}
                            {proposal.navrhovatel.prijmeni}</td
                        >
                    </tr>
                {/if}

                <tr>
                    <td><strong>Podpora</strong></td>
                    <td class="u-align--right"
                        >{proposal.pocetHlasuPozitivnich}</td
                    >
                </tr>

                <tr>
                    <td><strong>Odmítnutí</strong></td>
                    <td class="u-align--right"
                        >{proposal.pocetHlasuNegativnich}</td
                    >
                </tr>

                <tr>
                    <td><strong>Stav</strong></td>
                    <td class="u-align--right">
                        <span class="u-color--red"
                            >{proposal.stav && proposal.stav.nazevVerejny
                                ? proposal.stav.nazevVerejny
                                : ""}</span
                        >
                    </td>
                </tr>

                {#if proposal.datumUkonceni}
                    <tr>
                        <td><strong>Sbírá podporu do</strong></td>
                        <td class="u-align--right">
                            {DateUtils.formatDate(proposal.datumUkonceni, true)}
                        </td>
                    </tr>
                {/if}

                <tr>
                    <td><strong>Oblast</strong></td>
                    <td class="u-align--right">
                        <span class="bno-chip">
                            <span class="bno-chip__inner">
                                {proposal.kategorie && proposal.kategorie.nazev
                                    ? proposal.kategorie.nazev
                                    : ""}
                            </span>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
{/if}
