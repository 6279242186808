import AppModule from "../module";
import ListModule from "./list-module.svelte";
import HomeListModule from "./home-list-module.svelte";
import FilterModule from "./filter-module.svelte";
import SortModule from "./sort-module.svelte";
import BadListModule from "./bad-list-module.svelte";
import StatisticsModule from "./statistics-module.svelte";
import DataProvider from "./providers/data.provider";
import ServiceProvider from "./providers/service.provider";
import ListService from "./services/list.service";
import DataService from "../../services/data.service";
import { ListType } from "../../services/interface";
class ProposalListModule extends AppModule {
    constructor(options) {
        super(options);
        this._setup();
    }
    _setup() {
        this.mainSetup();
        this.dataProvider = new DataProvider(this.options);
        this.serviceProvider = new ServiceProvider(new ListService(this.options), new DataService);
        if (this.options.type === ListType.DEFAULT) {
            this._verifyFilterContainer();
            this._verifySortContainer();
            this._initListApp();
            this._initFilterApp();
            this._initSortApp();
        }
        if (this.options.type === ListType.HOME) {
            this._initHomeListApp();
            this._initStatisticsApp();
        }
        if (this.options.type === ListType.BAD) {
            this._initBadListApp();
        }
    }
    _initListApp() {
        this.listInstance = new ListModule({
            target: this.targetContainer,
            props: {
                serviceProvider: this.serviceProvider,
                prefix: this.options.prefix
            }
        });
    }
    _initHomeListApp() {
        this.listInstance = new HomeListModule({
            target: this.targetContainer,
            props: {
                serviceProvider: this.serviceProvider,
                prefix: this.options.prefix
            }
        });
    }
    _initBadListApp() {
        this.listInstance = new BadListModule({
            target: this.targetContainer,
            props: {
                serviceProvider: this.serviceProvider
            }
        });
    }
    _initFilterApp() {
        this.filterInstance = new FilterModule({
            target: this.options.filterEl,
            props: {
                serviceProvider: this.serviceProvider
            }
        });
    }
    _initSortApp() {
        this.filterInstance = new SortModule({
            target: this.options.sortEl,
            props: {
                serviceProvider: this.serviceProvider
            }
        });
    }
    _initStatisticsApp() {
        this.filterInstance = new StatisticsModule({
            target: this.options.statisticsEl,
            props: {
                serviceProvider: this.serviceProvider
            }
        });
    }
    // OPTIONS
    _verifyFilterContainer() {
        const { filterEl } = this.options;
        if (!(filterEl instanceof HTMLElement)) {
            throw new Error("The container for displaying filter does not exist");
        }
    }
    _verifySortContainer() {
        const { sortEl } = this.options;
        if (!(sortEl instanceof HTMLElement)) {
            throw new Error("The container for displaying sort does not exist");
        }
    }
    get options() {
        return this.commonOptions;
    }
}
const elementHomeList = document.getElementById('app-home-proposal-list');
const elementList = document.getElementById('app-proposal-list');
const elementFilter = document.getElementById('app-proposal-filter');
const elementBadList = document.getElementById('app-bad-proposal-list');
const elementStatistics = document.getElementById('app-proposal-statistics');
const elementSort = document.getElementById('app-proposal-sort');
if (elementHomeList && elementStatistics) {
    const elementHomePrefix = elementHomeList.getAttribute('data-url-prefix');
    new ProposalListModule({ el: elementHomeList, statisticsEl: elementStatistics, type: ListType.HOME, prefix: elementHomePrefix });
}
if (elementBadList) {
    new ProposalListModule({ el: elementBadList, type: ListType.BAD });
}
if (elementList && elementFilter && elementSort) {
    const elementPrefix = elementList.getAttribute('data-url-prefix');
    new ProposalListModule({ el: elementList, filterEl: elementFilter, sortEl: elementSort, type: ListType.DEFAULT, prefix: elementPrefix });
}
