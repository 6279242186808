<script lang="ts">
    import type { Service } from "./providers/service.provider";
    import type { Area } from "../../services/data.service";
    import type {
        ProposalFormData,
        NewsletterData,
    } from "./services/create.service";
    import Input from "../../components/Form/Input.svelte";
    import Select from "../../components/Form/Select.svelte";
    import Textarea from "../../components/Form/Textarea.svelte";
    import Checkbox from "../../components/Form/Checkbox.svelte";
    import FileInput from "../../components/Form/FileInput.svelte";
    import type { FileSelect } from "../../services/interface";
    import { Validators } from "../../components/Form/Validators";
    import type { Errors, FormData } from "../../components/Form/Form";
    import FormContainer from "../../components/Form/FormContainer.svelte";
    import ErrorMessage from "../../components/Form/ErrorMessage.svelte";
    import SMap from "../../components/Map/SMap.svelte";
    import { tick } from "svelte";

    export let serviceProvider: Service;

    let formEl: any;
    let errors: Errors = {};
    let form: FormData = {
        nazev: { validators: [Validators.required] },
        kategorie: { validators: [Validators.required] },
        popis: { validators: [Validators.required] },
        prospech: { validators: [Validators.required] },
        jmeno: {
            validators: [Validators.required],
        },
        prijmeni: {
            validators: [Validators.required],
        },
        email: { validators: [Validators.required, Validators.emailValid] },
        gdprSouhlas: { validators: [Validators.required] },
    };

    let newsletter: boolean = false;

    let proposal: ProposalFormData = {
        nazev: null,
        popis: null,
        prospech: null,
        kategorieId: null,
        nazevSouboru: null,
        navrhNahled: null,

        navrhovatel: {
            jmeno: null,
            prijmeni: null,
            email: null,
        },

        gpsSouradnice: {
            souradniceX: null,
            souradniceY: null,
        },

        zverejnitUdaje: false,
        kontaktovatNavrhovatele: false,
        gdprSouhlas: false,
    };

    let mapa: boolean = false;
    let areas: Area[] = [];
    let success: boolean = false;
    let processing: boolean = false;
    let error: string = "";
    let fileError: boolean = false;
    let file: FileSelect = { name: null, view: null };

    let errorMsgs: object = {
        ERR_TYP_OBRAZKU_NENI_PODPOROVANY:
            "Obrázek návrhu musí být typu JPG, JPEG nebo PNG.",
        ERR_NELZE_URCIT_TYP_OBRAZKU:
            "Obrázek návrhu je pravděpodobně rozbitý, nelze určit jeho typ.",
        ERR_OBRAZEK_NEDOSTATECNE_SIROKY:
            "Obrázek návrhu musí mít šířku minimálně 500px.",
        ERR_NAZEV_SOUBORU_PRILIS_DLOUHY:
            "Název souboru obrázku je příliš dlouhý, maximální délka názvu (včetně přípony) je 100 znaků.",
    };

    serviceProvider.dataService.areasValidSubs.subscribe(
        (data) => (areas = data)
    );
    serviceProvider.dataService.loadValidAreas();

    const onSubmit = async (e: any) => {
        error = "";
        processing = true;
        if (e?.detail?.valid && !fileError) {
            Promise.all([createNewsletter(), createNavrh()])
                .then(([resultNewsletter, resultNavrh]) => (processing = false))
                .catch((err: any) => (processing = false));
        } else {
            await tick();
            let element = document.querySelector(
                "#proposalForm .bno-form-control__message-error"
            );
            if (element) {
                const formControlElement = element.parentElement.parentElement;
                if (formControlElement) {
                    window.scrollTo(0, formControlElement.offsetTop);
                }
            }
            processing = false;
        }
    };

    function createNewsletter() {
        return new Promise((resolve) => {
            if (newsletter) {
                let newsletter: NewsletterData = {
                    emailOdberatele:
                        proposal.navrhovatel.email &&
                        proposal.navrhovatel.email.trim()
                            ? proposal.navrhovatel.email.trim()
                            : undefined,
                    gdprSouhlas: true,
                };

                serviceProvider.createService
                    .createNewsletter(newsletter)
                    .then(() => (success = true))
                    .catch((err) => {})
                    .finally(() => resolve(null));
            } else {
                resolve(null);
            }
        });
    }

    function createNavrh() {
        return new Promise((resolve) => {
            let proposalToSave = JSON.parse(JSON.stringify(proposal));

            if (!mapa) {
                proposalToSave.gpsSouradnice = undefined;
            }

            proposalToSave.zverejnitUdaje = !proposal.zverejnitUdaje;
            proposalToSave.kontaktovatNavrhovatele =
                !proposal.kontaktovatNavrhovatele;

            proposalToSave.nazev =
                proposal.nazev && proposal.nazev.trim()
                    ? proposal.nazev.trim()
                    : undefined;
            proposalToSave.popis =
                proposal.popis && proposal.popis.trim()
                    ? proposal.popis.trim()
                    : undefined;
            proposalToSave.prospech =
                proposal.prospech && proposal.prospech.trim()
                    ? proposal.prospech.trim()
                    : undefined;

            if (file) {
                proposalToSave.navrhNahled = file.view ? file.view : undefined;
                proposalToSave.nazevSouboru = file.name ? file.name : undefined;
            } else {
                proposalToSave.navrhNahled = undefined;
                proposalToSave.nazevSouboru = undefined;
            }

            if (proposal.navrhovatel) {
                proposalToSave.navrhovatel.jmeno =
                    proposal.navrhovatel.jmeno &&
                    proposal.navrhovatel.jmeno.trim()
                        ? proposal.navrhovatel.jmeno.trim()
                        : undefined;
                proposalToSave.navrhovatel.prijmeni =
                    proposal.navrhovatel.prijmeni &&
                    proposal.navrhovatel.prijmeni.trim()
                        ? proposal.navrhovatel.prijmeni.trim()
                        : undefined;
                proposalToSave.navrhovatel.email =
                    proposal.navrhovatel.email &&
                    proposal.navrhovatel.email.trim()
                        ? proposal.navrhovatel.email.trim()
                        : undefined;
            }

            serviceProvider.createService
                .create(proposalToSave)
                .then(() => (success = true))
                .catch((err) => {
                    error =
                        (err &&
                            err.response &&
                            err.response.data &&
                            errorMsgs[(<any>err.response.data).errorSubCode]) ||
                        "Došlo k neznámé chybě. Opakujte akci později.";
                })
                .finally(() => resolve(null));
        });
    }

    const onFileSelect = (e: any): void => {
        error = "";
        let detail = e.detail;
        file = {
            name: detail.name,
            view: detail.view,
        };
    };

    const onPlaceSelect = (e: any): void => {
        if (e) {
            if (e.detail) {
                let coords = JSON.parse(e.detail);

                proposal.gpsSouradnice.souradniceX =
                    coords.features[0].geometry.coordinates[0];
                proposal.gpsSouradnice.souradniceY =
                    coords.features[0].geometry.coordinates[1];
            }
        }
    };

    const initNavrh = (_e: any): void => {
        success = false;
        mapa = false;
        newsletter = false;
        proposal = {
            nazev: null,
            popis: null,
            prospech: null,
            kategorieId: null,
            nazevSouboru: null,
            navrhNahled: null,

            navrhovatel: {
                jmeno: null,
                prijmeni: null,
                email: null,
            },

            gpsSouradnice: {
                souradniceX: null,
                souradniceY: null,
            },

            zverejnitUdaje: false,
            kontaktovatNavrhovatele: false,
            gdprSouhlas: false,
        };
    };
</script>

{#if !success}
    <FormContainer
        {form}
        on:submit={onSubmit}
        bind:this={formEl}
        ariaLabel="Podání návrhu"
        id="proposalForm"
    >
        <Input
            bind:value={proposal.nazev}
            name="nazev"
            label="Zadejte název návrhu"
            hint="Vymyslete pro svůj návrh krátký a výstižný název. Maximálně 100 znaků"
            maxlength={100}
            style="max-width: 500px"
        >
            <span slot="errors">
                <ErrorMessage
                    fieldName="nazev"
                    errorKey="required"
                    message="Zadejte název návrhu"
                />
            </span>
        </Input>

        <Select
            bind:value={proposal.kategorieId}
            name="kategorie"
            label="Vyberte oblast"
            style="max-width: 340px"
        >
            <span slot="errors">
                <ErrorMessage
                    fieldName="kategorie"
                    errorKey="required"
                    message="Vyberte oblast"
                />
            </span>
            <option value selected hidden>Vyberte</option>
            {#each areas as area}
                <option value={area.id}>{area.nazev}</option>
            {/each}
        </Select>

        <Textarea
            bind:value={proposal.popis}
            name="popis"
            maxlength={3500}
            label="Vysvětlete, o&nbsp;co jde, a&nbsp;proč to navrhujete"
            hint="Stručně shrňte, co je hlavním záměrem návrhu. Maximálně 3500 znaků"
            style="max-width: 650px"
        >
            <span slot="errors">
                <ErrorMessage
                    fieldName="popis"
                    errorKey="required"
                    message="Vysvětlete, o&nbsp;co jde, a&nbsp;proč to navrhujete"
                />
            </span>
        </Textarea>

        <Textarea
            bind:value={proposal.prospech}
            maxlength={3500}
            name="prospech"
            label="Vysvětlete veřejný prospěch návrhu"
            hint="Popište, k&nbsp;čemu má návrh sloužit. Každý podaný návrh musí být přínosný pro širší okruh lidí. Maximálně 3500 znaků"
            style="max-width: 650px"
        >
            <span slot="errors">
                <ErrorMessage
                    fieldName="prospech"
                    errorKey="required"
                    message="Vysvětlete veřejný prospěch návrhu"
                />
            </span>
        </Textarea>

        <FileInput
            name="soubor"
            label="Nahrajte obrázek"
            on:error={(e) => (fileError = e.detail)}
            on:fileSelect={(e) => onFileSelect(e)}
            hint="Pro lepší znázornění návrhu připojte fotografii, nákres či ilustraci. Minimální šířka obrázku je 500&nbsp;px (první údaj u&nbsp;rozměru obrázku), maximální velikost přiloženého souboru potom 4&nbsp;MB. Pokud obrázek nevložíte, bude návrhu přidělena ikona automaticky. Statutární město Brno nenese odpovědnost za obsah, autorství ani vhodnost vložených fotografií. Fotografie s&nbsp;vulgárním, citlivým nebo jinak závadným obsahem budou smazány."
            style="max-width: 420px"
        />

        <Checkbox
            name="lokalita"
            label="Návrh je vázán na lokalitu"
            hint="Pokud má návrh jedno pevné umístění, zatrhněte volbu a označte bod v&nbsp;mapě. Pokud má návrh více umístění nebo není vázán na jedno konkrétní místo, ponechte bez zatrhnutí."
            bind:value={mapa}
            style="max-width: 650px; margin-top: 4.6875rem;"
        />

        {#if mapa}
            <div style="max-width: 650px; height: 400px;">
                <SMap on:placeSelect={onPlaceSelect} />
            </div>
        {/if}

        <div class="bno-content-wrap">
            <h2>Navrhovatel</h2>

            <Input
                bind:value={proposal.navrhovatel.jmeno}
                name="jmeno"
                maxlength={30}
                label="Zadejte jméno"
                hint="Maximálně 30 znaků"
                style="max-width: 330px"
            >
                <span slot="errors">
                    <ErrorMessage
                        fieldName="jmeno"
                        errorKey="required"
                        message="Zadejte jméno"
                    />
                </span>
            </Input>

            <Input
                bind:value={proposal.navrhovatel.prijmeni}
                name="prijmeni"
                label="Zadejte příjmení"
                maxlength={35}
                hint="Maximálně 35 znaků"
                style="max-width: 330px"
            >
                <span slot="errors">
                    <ErrorMessage
                        fieldName="prijmeni"
                        errorKey="required"
                        message="Zadejte příjmení"
                    />
                </span>
            </Input>

            <Checkbox
                bind:value={proposal.zverejnitUdaje}
                name="zverejnitUdaje"
                label="Nechci zveřejnit své jméno a&nbsp;příjmení"
                style="position: relative; top: -16px"
            />

            <Checkbox
                bind:value={proposal.kontaktovatNavrhovatele}
                name="zverejnitUdaje"
                label="Nechci být kontaktován veřejností"
                style="position: relative; top: -16px"
            />

            <Input
                bind:value={proposal.navrhovatel.email}
                name="email"
                maxlength={254}
                label="Zadejte kontaktní e-mail"
                hint="Vaše e-mailová adresa nebude zveřejněna, slouží pouze pro komunikaci s&nbsp;úřadem. Maximálně 254 znaků"
                style="max-width: 330px"
            >
                <span slot="errors">
                    <ErrorMessage
                        fieldName="email"
                        errorKey="required"
                        message="Zadejte kontaktní e-mail"
                    />
                    <ErrorMessage fieldName="email" errorKey="emailValid" />
                </span>
            </Input>

            <Checkbox
                bind:value={newsletter}
                name="newsletter"
                label="Souhlasím se zasíláním novinek z Dáme na vás"
            />

            <Checkbox bind:value={proposal.gdprSouhlas} name="gdprSouhlas">
                <span slot="label">
                    Souhlasím se <a
                        href="/zpracovani-osobnich-udaju"
                        class="bno-link bno-link--spread-max"
                        target="_blank"
                    >
                        zpracováním osobních údajů</a
                    >
                </span>
                <span slot="errors">
                    <ErrorMessage
                        fieldName="gdprSouhlas"
                        errorKey="required"
                        message="Musíte souhlasit se zpracováním osobních údajů."
                    />
                </span>
            </Checkbox>
        </div>

        <div class="bno-content-wrap">
            <button
                class="bno-button bno-button--primary"
                type="submit"
                disabled={processing}
            >
                {#if processing}
                    Podávám návrh...
                {:else}
                    Podat návrh
                {/if}
            </button>
        </div>
    </FormContainer>
    {#if error}
        <div class="bno-form-message bno-form-message--error">
            <p>{error}</p>
        </div>
    {/if}
{/if}

{#if success}
    <h2>
        Děkujeme za Váš návrh, který byl úspěšně vytvořen a nyní čeká na Vaše
        potvrzení!
    </h2>
    <p>
        Podívejte se do Vaší e-mailové schránky a potvrďte odkaz pro finální
        podání návrhu. Bez potvrzení nebude možné Vámi zadaný návrh posoudit ze
        strany pracovníků Magistrátu města Brna. Děkujeme a přejeme hezký den!
    </p>
    <p>Váš tým Dáme na vás</p>

    <div class="bno-content-wrap bno-content-wrap--buttons">
        <button
            class="bno-button bno-button--primary"
            on:click={() => (window.location.href = "/galerie-navrhu")}
            >Galerie návrhů
        </button>

        <button class="bno-button bno-button--secondary" on:click={initNavrh}
            >Podat návrh
        </button>
    </div>
{/if}
